import dayjs from 'dayjs'
import React from 'react'

import { useGetJWT } from '@vori/dashboard-rest-next/courier/courier'

import { ENV_USE_MOCKS } from '@vori/dashboard-env'
import { FeatureFlags } from '@vori/dashboard-constants'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { decodeToken, isTokenExpired } from './utils'
import { useGlobalStore } from '../../state-management/store'

const RETRY_TOKEN_FETCH_INTERVAL_IN_MS = 600000

/**
 * Fetches a JWT granting access to the current user's notifications,
 * via Courier.
 *
 * @see {@link https://www.courier.com/}
 */
export function useCourierToken(): string | null {
  const { user } = useCurrentUserState()

  const {
    courierToken,
    isFetchCountBelowMaxThreshold,
    incrementCourierTokenFetchCount,
    updateCourierToken,
    resetCourierTokenFetchCount,
  } = useGlobalStore((state) => state.appData)

  const [retryTokenFetchInterval] = React.useState<NodeJS.Timeout>(
    setInterval(() => {
      resetCourierTokenFetchCount()
    }, RETRY_TOKEN_FETCH_INTERVAL_IN_MS),
  )
  const refreshTokenIntervalRef = React.useRef<null | NodeJS.Timeout>(null)

  const canSeeCourierInbox = useFeatureConfig(
    FeatureFlags.WEB_NOTIFICATIONS_INBOX,
  )

  const { data, mutate } = useGetJWT({
    swr: {
      enabled:
        user.state.isLoggedIn &&
        canSeeCourierInbox &&
        isTokenExpired(courierToken) &&
        isFetchCountBelowMaxThreshold() &&
        !ENV_USE_MOCKS,
      onSuccess: () => {
        incrementCourierTokenFetchCount()
      },
      onError: () => {
        incrementCourierTokenFetchCount()
      },
    },
  })

  React.useEffect(() => {
    if (
      data != null &&
      (courierToken !== data.token || !refreshTokenIntervalRef.current)
    ) {
      updateCourierToken(data.token)

      const intervalLengthInSeconds =
        decodeToken(data.token).exp - dayjs(new Date()).unix()
      refreshTokenIntervalRef.current = setInterval(
        () => {
          mutate()
        },
        intervalLengthInSeconds <= 0
          ? RETRY_TOKEN_FETCH_INTERVAL_IN_MS
          : intervalLengthInSeconds * 1000,
      )
    }

    const refreshInterval = refreshTokenIntervalRef.current

    return () => {
      if (refreshInterval) {
        refreshTokenIntervalRef.current = null
        clearInterval(refreshInterval)
      }
    }
  }, [
    canSeeCourierInbox,
    courierToken,
    data,
    mutate,
    updateCourierToken,
    user.state.isLoggedIn,
  ])

  React.useEffect(() => {
    return () => clearInterval(retryTokenFetchInterval)
  }, [retryTokenFetchInterval])

  return courierToken
}
