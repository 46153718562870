/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum DuplicatedPriorityErrorErrorCode {
  duplicated_custom_quick_action_priority = 'duplicated_custom_quick_action_priority',
}
