/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  CreateReportingDefaultBookmarkDto,
  DeleteReportingDefaultBookmarkDto,
  GetReportingDefaultBookmarkParams,
  ListFeaturesParams,
  ListFeaturesResponseDto,
  ReportingDefaultBookmarkDto,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getReportingDefaultBookmark = (
  params: GetReportingDefaultBookmarkParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ReportingDefaultBookmarkDto>(
    { url: `/v1/me/reporting-default-bookmarks`, method: 'GET', params },
    options,
  )
}

export const getGetReportingDefaultBookmarkKey = (
  params: GetReportingDefaultBookmarkParams,
) =>
  [`/v1/me/reporting-default-bookmarks`, ...(params ? [params] : [])] as const

export type GetReportingDefaultBookmarkQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReportingDefaultBookmark>>
>
export type GetReportingDefaultBookmarkQueryError = ErrorType<unknown>

export const useGetReportingDefaultBookmark = <TError = ErrorType<unknown>>(
  params: GetReportingDefaultBookmarkParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getReportingDefaultBookmark>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetReportingDefaultBookmarkKey(params) : null))
  const swrFn = () => getReportingDefaultBookmark(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createReportingDefaultBookmark = (
  createReportingDefaultBookmarkDto: CreateReportingDefaultBookmarkDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CreateReportingDefaultBookmarkDto>(
    {
      url: `/v1/me/reporting-default-bookmarks`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createReportingDefaultBookmarkDto,
    },
    options,
  )
}

export const getCreateReportingDefaultBookmarkMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateReportingDefaultBookmarkDto },
  ): Promise<CreateReportingDefaultBookmarkDto> => {
    return createReportingDefaultBookmark(arg, options)
  }
}
export const getCreateReportingDefaultBookmarkMutationKey = () =>
  [`/v1/me/reporting-default-bookmarks`] as const

export type CreateReportingDefaultBookmarkMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReportingDefaultBookmark>>
>
export type CreateReportingDefaultBookmarkMutationError = ErrorType<void>

export const useCreateReportingDefaultBookmark = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createReportingDefaultBookmark>>,
    TError,
    Key,
    CreateReportingDefaultBookmarkDto,
    Awaited<ReturnType<typeof createReportingDefaultBookmark>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getCreateReportingDefaultBookmarkMutationKey()
  const swrFn = getCreateReportingDefaultBookmarkMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteReportingDefaultBookmark = (
  deleteReportingDefaultBookmarkDto: DeleteReportingDefaultBookmarkDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    {
      url: `/v1/me/reporting-default-bookmarks`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: deleteReportingDefaultBookmarkDto,
    },
    options,
  )
}

export const getDeleteReportingDefaultBookmarkMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: DeleteReportingDefaultBookmarkDto },
  ): Promise<void> => {
    return deleteReportingDefaultBookmark(arg, options)
  }
}
export const getDeleteReportingDefaultBookmarkMutationKey = () =>
  [`/v1/me/reporting-default-bookmarks`] as const

export type DeleteReportingDefaultBookmarkMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReportingDefaultBookmark>>
>
export type DeleteReportingDefaultBookmarkMutationError = ErrorType<void>

export const useDeleteReportingDefaultBookmark = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof deleteReportingDefaultBookmark>>,
    TError,
    Key,
    DeleteReportingDefaultBookmarkDto,
    Awaited<ReturnType<typeof deleteReportingDefaultBookmark>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteReportingDefaultBookmarkMutationKey()
  const swrFn = getDeleteReportingDefaultBookmarkMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listFeatures = (
  params?: ListFeaturesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ListFeaturesResponseDto>(
    { url: `/v1/users/features`, method: 'GET', params },
    options,
  )
}

export const getListFeaturesKey = (params?: ListFeaturesParams) =>
  [`/v1/users/features`, ...(params ? [params] : [])] as const

export type ListFeaturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFeatures>>
>
export type ListFeaturesQueryError = ErrorType<void>

export const useListFeatures = <TError = ErrorType<void>>(
  params?: ListFeaturesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listFeatures>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListFeaturesKey(params) : null))
  const swrFn = () => listFeatures(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
