import merge from 'lodash/merge'
import React from 'react'

import { AppGetCurrentUserQuery, UserType } from '@vori/gql-dashboard'

import {
  CurrentUserData,
  CurrentUserReducerAction,
  CurrentUserReducerState,
} from './types'

import { getDefaultUserMetadata, userDataIsFromGQL } from './utils'

export function getInitialReducerState(options?: {
  initialUserData?: Partial<CurrentUserData> | null
}): CurrentUserReducerState {
  const userData: CurrentUserData = merge(
    {
      canSeeFeatures: { enabledFeatures: [] },
      companyID: '',
      companyImageURL: '',
      companyName: '',
      displayName: '',
      email: '',
      id: '',
      isBuyer: false,
      isDepartmentManager: false,
      isRetailUser: false,
      isSalesRep: false,
      isStoreAdmin: false,
      isStoreAP: false,
      isVendorAdmin: false,
      isVendorUser: false,
      isVoriAdmin: false,
      retailUser: null,
      userSqlID: '',
      userType: null,
      vendorUser: null,
    },
    options?.initialUserData,
  )

  return {
    authenticationError: null,
    user: {
      authToken: null,
      data: userData,
      metadata: getDefaultUserMetadata(userData),
      state: {
        isAuthenticating: true,
        isLoggedIn: false,
      },
    },
  }
}

export function reducer(
  state: CurrentUserReducerState,
  action: CurrentUserReducerAction,
): CurrentUserReducerState {
  switch (action.type) {
    case 'userV2/authenticationStarted': {
      return {
        authenticationError: null,
        user: {
          ...state.user,
          state: {
            ...state.user.state,
            isAuthenticating: true,
          },
        },
      }
    }

    case 'userV2/authenticated': {
      const userData = userDataIsFromGQL(action.payload.data)
        ? {
            canSeeFeatures: action.payload.data.canSeeFeatures || {
              enabledFeatures: [],
            },
            companyID:
              action.payload.data.vendorUser?.vendor.id ||
              action.payload.data.retailUser?.retailCompany.id ||
              '',
            companyImageURL: action.payload.data.companyImageURL || '',
            companyName:
              action.payload.data.vendorUser?.vendor.name ||
              action.payload.data.retailUser?.retailCompany.name ||
              '',
            displayName: action.payload.data.displayName || '',
            email: action.payload.data.email || '',
            id: action.payload.data.id || '',
            isBuyer: action.payload.data.userType === UserType.Buyer,
            isDepartmentManager:
              action.payload.data.userType === UserType.DepartmentManager,
            isRetailUser:
              Boolean(action.payload.data.userType) &&
              [
                UserType.Buyer,
                UserType.DepartmentManager,
                UserType.StoreAccountsPayable,
                UserType.StoreAdmin,
                UserType.StoreReceiver,
              ].includes(action.payload.data.userType as UserType),
            isSalesRep: action.payload.data.userType === UserType.SalesRep,
            isStoreAdmin: action.payload.data.userType === UserType.StoreAdmin,
            isStoreAP:
              action.payload.data.userType === UserType.StoreAccountsPayable,
            isVendorAdmin:
              action.payload.data.userType === UserType.VendorAdmin,
            isVendorUser:
              Boolean(action.payload.data.userType) &&
              [UserType.SalesRep, UserType.VendorAdmin].includes(
                action.payload.data.userType as UserType,
              ),
            isVoriAdmin: action.payload.data.userType === UserType.VoriAdmin,
            retailUser:
              action.payload.data.retailUser ||
              ({ retailCompany: {}, user: {} } as NonNullable<
                AppGetCurrentUserQuery['me']
              >['user']['retailUser']),
            userSqlID: action.payload.data.userSqlID,
            userType: action.payload.data.userType,
            vendorUser:
              action.payload.data.vendorUser ||
              ({ vendor: {} } as NonNullable<
                AppGetCurrentUserQuery['me']
              >['user']['vendorUser']),
          }
        : action.payload.data

      return {
        ...state,
        user: {
          ...state.user,
          authToken: action.payload.authToken,
          data: userData,
          metadata: getDefaultUserMetadata(userData),
          state: {
            isAuthenticating: false,
            isLoggedIn: true,
          },
        },
      }
    }

    case 'userV2/authenticationFailed': {
      const nextState = getInitialReducerState()

      return {
        authenticationError: action.payload.error,
        user: {
          ...nextState.user,
          state: {
            ...nextState.user.state,
            isAuthenticating: false,
          },
        },
      }
    }

    case 'userV2/unauthenticated': {
      const nextState = getInitialReducerState()

      return {
        ...nextState,
        user: {
          ...nextState.user,
          state: {
            isAuthenticating: false,
            isLoggedIn: false,
          },
        },
      }
    }

    case 'userV2/storeSelected': {
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            selectedStoreID: action.payload.selectedStoreID,
            selectedStoreName: action.payload.selectedStoreName,
            timeZone: action.payload.selectedStoreTimeZone,
          },
        },
      }
    }

    case 'userV2/featureFlagsUpdated': {
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            canSeeFeatures: {
              enabledFeatures: action.payload.featureFlags,
            },
          },
        },
      }
    }

    default: {
      return state
    }
  }
}

export function useCurrentUserReducer(
  initialUserData?: Partial<CurrentUserData> | null,
): [CurrentUserReducerState, React.Dispatch<CurrentUserReducerAction>] {
  return React.useReducer(reducer, { initialUserData }, getInitialReducerState)
}
