import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import Decimal from 'decimal.js'

const DEFAULT_ROUNDING_RULES = ['09', 19, 29, 39, 49, 59, 69, 79, 89, 99]

export function applyRoundingRule(
  number: Decimal,
  increments?: (number | string)[],
): Decimal {
  const value = number.times(100).round()
  const { base, cents } = splitValue(value.toNumber())

  const rounded = getRoundedCentValue(
    cents,
    increments || DEFAULT_ROUNDING_RULES,
  )

  return toDecimal(`${base}${rounded}`) as Decimal
}

const getRoundedCentValue = (
  cents: number,
  increments: (number | string)[],
) => {
  let roundedValue = increments[0]
  for (let i = 0; i < increments.length; i++) {
    const prevInc = Number(increments[i - 1])
    const currInc = Number(increments[i])
    if (cents <= currInc && cents > prevInc) {
      roundedValue = currInc
    }
  }
  return roundedValue
}

const splitValue = (value: number) => {
  return {
    base: Number(value.toString().slice(0, -2)),
    cents: Number(value.toString().slice(-2)),
  }
}

export const toPriceAndCents = (value: number | string): string | undefined => {
  if (value === '' || value === null) return ''
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(Number(value))
    .replace(/,/, '')
    .replace('$', '')
}

export const toDecimal = (
  value: Decimal | number | string | undefined | null,
): Decimal | null => {
  if (value === '' || value === null || value === undefined) return null
  try {
    return new Decimal(value)
  } catch (e) {
    console.warn('Error converting to Decimal', e)
    captureException(() => e as Error)

    return null
  }
}
