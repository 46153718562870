/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum InventoryChangeSourceType {
  USER_OVERRIDE = 'USER_OVERRIDE',
  POS_SALE = 'POS_SALE',
  POS_REFUND = 'POS_REFUND',
  INVOICE_SHIPPED_QTY = 'INVOICE_SHIPPED_QTY',
  HANDHELD_INVENTORY_RESTOCK = 'HANDHELD_INVENTORY_RESTOCK',
  HANDHELD_INVENTORY_SHRINK = 'HANDHELD_INVENTORY_SHRINK',
  HANDHELD_INVENTORY_COUNT = 'HANDHELD_INVENTORY_COUNT',
  HANDHELD_INVENTORY_MANUAL = 'HANDHELD_INVENTORY_MANUAL',
  HANDHELD_PRODUCT_MANUAL = 'HANDHELD_PRODUCT_MANUAL',
}
