/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum PaymentType {
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  EBT_CASH = 'EBT CASH',
  EBT_FOODSTAMP = 'EBT FOODSTAMP',
  EWIC = 'EWIC',
  GIFT_CARD = 'GIFT CARD',
}
