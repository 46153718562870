import {
  createStoreVendorProduct,
  deleteStoreVendorProduct,
  deleteStoreVendorProducts,
  getStoreProduct,
  getStoreVendorProduct,
  listVendorProducts,
  updateStoreVendorProduct,
} from '@vori/dashboard-rest-next/products/products'
import camelCase from 'lodash/camelCase'
import { StateCreator } from 'zustand'
import { queryClientInstance } from './networking/queryClient'
import { StoreVendorProductDto } from '@vori/dashboard-rest-next/schemas/storeVendorProductDto'
import {
  VendorDto,
  VendorBaseDTO,
  CreateStoreVendorProductDto,
  VendorProductDto,
  StoreVendorDto,
  StoreProductDto,
} from '@vori/dashboard-rest-next/schemas'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import {
  StateActionStatus,
  getQueryClientCacheReducer,
  GlobalStoreType,
} from './storeTypesAndUtils'
import {
  getStoreVendor,
  listStoreVendors,
} from '@vori/dashboard-rest-next/store-vendors/store-vendors'
import Decimal from 'decimal.js'

export type VendorProductPerCostOptions = 'case_cost' | 'lb_cost' | 'each_cost'
export type VendorProductUOM = 'CASE' | 'LB' | 'EACH'

export type UOM_OPTION = {
  id: VendorProductUOM
  label: string
  UOM: VendorProductPerCostOptions
}

export const UOM_OPTIONS: Record<string, UOM_OPTION> = {
  CASE: { id: 'CASE', label: 'per case', UOM: 'case_cost' },
  LB: { id: 'LB', label: 'per LB', UOM: 'lb_cost' },
  EACH: { id: 'EACH', label: 'per each', UOM: 'each_cost' },
}

export type ExpandedStoreVendorProduct = {
  unit_volume?: string | null
  unit_cost?: string | null
  cost?: string
  active_per_cost?: VendorProductUOM
  store_vendor_id?: string
  full_store_product?: null | StoreProductDto
} & StoreVendorProductDto

export type VendorsSlice = {
  loading: boolean
  success: boolean
  error: unknown | null
  deleteVendorProductsStatus: StateActionStatus
  bulkDeleteVendorProductsStatus: StateActionStatus
  getStoreProductStatus: StateActionStatus
  fetchStoreProductByIdStatus: StateActionStatus
  activeAbortController: null | AbortController
  activeStoreVendorId: string | null
  activeStoreVendor: StoreVendorDto | null
  activeStoreVendorProductId: string | null
  storeVendorProducts: Map<string, StoreVendorProductDto[]>
  vendorProductCatalog: VendorProductDto[]
  pendingStoreVendorProduct: Partial<ExpandedStoreVendorProduct>
  storeVendors: VendorBaseDTO[]
  currentStoreVendorsPageIndex: number
  currentStoreVendorProductsPageIndex: number
  resetSaveState: () => void
  updatePendingStoreVendorProduct: (
    updatedInfo: Partial<ExpandedStoreVendorProduct>,
    changedFields?: string[],
    perCostChange?: VendorProductUOM,
  ) => void
  fetchAndSetActiveStoreProductById: (
    productId: string,
    replacePendingSVP?: boolean,
  ) => void
  fetchVendorProducts: (
    vendorId?: string,
    searchTerm?: string,
    searchField?: string,
  ) => void
  fetchActiveStoreVendor: () => void
  fetchStoreVendors: () => void
  setActiveVendorProduct: (
    vendorProduct?: ExpandedStoreVendorProduct | null,
  ) => void
  setActiveVendorId: (incomingVendorId: string | null) => void
  saveModifiedStoreVendorProduct: (
    saveData: Partial<ExpandedStoreVendorProduct>,
    isEditingExistingProduct?: boolean,
    callback?: () => void,
  ) => void
  deleteStoreVendorProduct: (
    storeVendorProductId: string,
    callback?: () => void,
  ) => void
  bulkDeleteStoreVendorProducts: (
    storeVendorProductIds: string[],
    callback?: () => void,
  ) => void
}

export const createVendorsSlice: StateCreator<VendorsSlice> = (
  set,
  get,
  store,
) => ({
  loading: false,
  success: false,
  error: null,
  deleteVendorProductsStatus: { error: null, success: false, loading: false },
  bulkDeleteVendorProductsStatus: {
    error: null,
    success: false,
    loading: false,
  },
  getStoreProductStatus: { error: null, success: false, loading: false },
  fetchStoreProductByIdStatus: { error: null, success: false, loading: false },
  activeAbortController: null,
  activeStoreVendorId: null,
  activeStoreVendor: null,
  activeStoreVendorProductId: null,
  storeVendorProducts: new Map(),
  vendorProductCatalog: [],
  storeVendors: [],
  pendingStoreVendorProduct: {},
  currentStoreVendorsPageIndex: 1,
  currentStoreVendorProductsPageIndex: 1,
  resetSaveState: () => {
    set({
      loading: false,
      success: false,
    })
  },
  setActiveVendorProduct: async (vendorProduct = null) => {
    if (!vendorProduct) {
      set({
        activeStoreVendorProductId: null,
        pendingStoreVendorProduct: {},
      })
    } else {
      set({
        activeStoreVendorProductId: vendorProduct.id,
      })

      // early optimistic update of the pending product
      // the fetch might take a while in some cases and we don't want to
      // completely block partial data update unecessarily
      get().updatePendingStoreVendorProduct(
        vendorProduct || {},
        [],
        getPopulatedUOMType(vendorProduct),
      )

      if (vendorProduct.store_product_id !== null) {
        try {
          set({
            getStoreProductStatus: {
              error: null,
              success: false,
              loading: true,
            },
          })
          const vendorProductData = await getStoreProduct(
            vendorProduct.store_product_id,
          )
          vendorProduct.full_store_product = vendorProductData
        } catch (error) {
          set({
            getStoreProductStatus: {
              error: error as Error,
              success: false,
              loading: false,
            },
          })
        } finally {
          set({
            getStoreProductStatus: {
              error: null,
              success: false,
              loading: false,
            },
          })
        }
      }
      get().updatePendingStoreVendorProduct(
        vendorProduct || {},
        [],
        getPopulatedUOMType(vendorProduct),
      )
    }
  },
  setActiveVendorId: (incomingStoreVendorId = null) => {
    set({
      activeStoreVendorId: incomingStoreVendorId,
    })
  },
  fetchActiveStoreVendor: async () => {
    const activeStoreVendorId = get().activeStoreVendorId
    if (!activeStoreVendorId) {
      set({ activeStoreVendor: null })
      return
    }

    try {
      set({ loading: true })

      const storeVendorDto = await getStoreVendor(activeStoreVendorId)
      set({
        loading: false,
        error: null,
        activeAbortController: null,
        activeStoreVendor: storeVendorDto,
      })
    } catch (error) {
      set({
        error,
        loading: false,
        activeAbortController: null,
      })
    }
  },
  fetchStoreVendors: async () => {
    const globalState = store.getState() as unknown as GlobalStoreType
    const storeId = globalState.user.user?.metadata.selectedStoreID
    if (!storeId) {
      return
    }

    const currentController = new AbortController()
    const currentStoreVendorsPageIndex = get().currentStoreVendorsPageIndex
    const baseQueryKey = ['store-vendors']

    set({
      activeAbortController: currentController,
      loading: true,
    })

    const { has_next_page, cachedData, next_page_number } = queryClientInstance
      .getQueriesData<VendorDto>(baseQueryKey)
      .reduce(getQueryClientCacheReducer<VendorDto, VendorBaseDTO>, {
        has_next_page: true,
        next_page_number: currentStoreVendorsPageIndex,
        cachedData: [],
      })

    if (cachedData.length && !has_next_page) {
      set({
        loading: false,
        storeVendors: cachedData,
        activeAbortController: null,
      })
      return
    }

    const queryKey = [...baseQueryKey, next_page_number]

    try {
      const { data, meta } = await queryClientInstance.fetchQuery(
        queryKey,
        () =>
          listStoreVendors({
            signal: currentController.signal,
          }),
      )

      if (meta.has_next_page) {
        set({ currentStoreVendorsPageIndex: next_page_number + 1 })
      }

      set({
        loading: false,
        activeAbortController: null,
        storeVendors: [...cachedData, ...data],
      })
    } catch (error) {
      set({
        error: error,
        loading: false,
      })
    }
  },
  fetchAndSetActiveStoreProductById: async (
    productId,
    replacePendingSVP = false,
  ) => {
    try {
      const product = await getStoreVendorProduct(productId)
      set({
        fetchStoreProductByIdStatus: {
          error: null,
          loading: false,
          success: false,
        },
      })
      if (replacePendingSVP) {
        get().setActiveVendorProduct(product)
      }
    } catch (error) {
      set({
        fetchStoreProductByIdStatus: {
          error: error as Error,
          loading: false,
          success: false,
        },
      })
    }
  },
  fetchVendorProducts: async (vendorId, searchTerm, searchField) => {
    const storeVendorId = vendorId || get().activeStoreVendorId
    const vendorData = get().storeVendors.find(
      (vendor) => vendor.id === storeVendorId,
    )

    if (!vendorData || !vendorData.vendorID) {
      return
    }

    let filterModel = {}
    if (searchTerm && searchField) {
      filterModel = {
        [searchField]: {
          filterType: 'text',
          type: 'contains',
          filter: searchTerm,
        },
      }
    }

    set({
      loading: true,
    })

    try {
      set({ loading: true })

      const { data } = await listVendorProducts({
        vendor_id: vendorData.vendorID,
        state: JSON.stringify({
          rowGroupCols: [],
          valueCols: [],
          pivotCols: [],
          pivotMode: false,
          groupKeys: [],
          filterModel,
          sortModel: [],
        }),
      })

      set({
        loading: false,
        error: null,
        activeAbortController: null,
        vendorProductCatalog: data,
      })
    } catch (error) {
      set({
        error,
        loading: false,
        success: false,
        activeAbortController: null,
      })
    }
  },
  updatePendingStoreVendorProduct: (
    updatedInfo,
    changedFields,
    perCostChange,
  ) => {
    let currentPending = { ...get().pendingStoreVendorProduct }

    if (changedFields && changedFields.length) {
      changedFields.forEach((fieldName) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currentPending[fieldName] = updatedInfo[fieldName]
      })
    } else {
      currentPending = { ...currentPending, ...updatedInfo }
    }

    currentPending.active_per_cost = perCostChange
      ? perCostChange
      : currentPending.active_per_cost || 'CASE'

    switch (currentPending.active_per_cost) {
      case 'CASE':
        currentPending.cost = currentPending.case_cost || '0'
        break
      case 'EACH':
        currentPending.cost = updatedInfo.each_cost || '0'
        break
      case 'LB':
        currentPending.cost = updatedInfo.lb_cost || '0'
        break
    }

    currentPending.cost = new Decimal(currentPending.cost).toFixed(2)

    set({
      pendingStoreVendorProduct: currentPending,
    })
  },
  saveModifiedStoreVendorProduct: async (
    saveData,
    isEditingExistingProduct = false,
    callback,
  ) => {
    const updateContent = saveData || get().pendingStoreVendorProduct
    if (!isEditingExistingProduct) {
      const newProductDefinition =
        createSaveDataFromPendingItem<CreateStoreVendorProductDto>(
          updateContent,
        )

      set({
        loading: true,
        activeAbortController: null,
      })

      try {
        await createStoreVendorProduct(newProductDefinition)
        set({
          success: true,
        })
        if (callback) {
          callback()
        }
      } catch (error) {
        set({
          error,
          success: false,
          loading: false,
          activeAbortController: null,
        })
        captureException(
          (scope) => {
            scope.setContext('createStoreVendorProduct failed', {
              errorInfo: error,
            })
            return error as Error
          },
          {
            sentryTag: 'stateManagementCreateStoreVendorProduct',
          },
        )
      } finally {
        set({
          success: false,
          loading: false,
          error: null,
        })
      }
    } else {
      try {
        await updateStoreVendorProduct(
          updateContent.id!,
          createSaveDataFromPendingItem(updateContent),
        )

        set({
          success: true,
        })

        if (callback) {
          callback()
        }
      } catch (error) {
        set({
          error,
          loading: false,
          success: false,
          activeAbortController: null,
        })
        captureException(
          (scope) => {
            scope.setContext('updateStoreVendorProduct failed', {
              errorInfo: error,
            })
            return error as Error
          },
          {
            sentryTag: 'stateManagementUpdateStoreVendorProduct',
          },
        )
      } finally {
        set({
          success: false,
          loading: false,
          error: null,
        })
      }
    }
  },
  deleteStoreVendorProduct: async (storeVendorProductId, callback) => {
    set({
      deleteVendorProductsStatus: {
        loading: true,
        success: false,
        error: null,
      },
    })

    try {
      await deleteStoreVendorProduct(storeVendorProductId)
      set({
        deleteVendorProductsStatus: {
          loading: false,
          success: true,
          error: null,
        },
      })
      if (callback) {
        callback()
      }
    } catch (error) {
      set({
        deleteVendorProductsStatus: {
          loading: false,
          success: false,
          error: error as Error,
        },
      })
      captureException(
        (scope) => {
          scope.setContext('deleteStoreVendorProduct failed', {
            errorInfo: error,
          })
          return error as Error
        },
        {
          sentryTag: 'stateManagementDeleteStoreVendorProduct',
        },
      )
    } finally {
      set({
        deleteVendorProductsStatus: {
          loading: false,
          success: false,
          error: null,
        },
      })
    }
  },
  bulkDeleteStoreVendorProducts: async (storeVendorProductIds, callback) => {
    set({
      bulkDeleteVendorProductsStatus: {
        loading: true,
        success: false,
        error: null,
      },
    })

    try {
      await deleteStoreVendorProducts({ ids: storeVendorProductIds })
      set({
        bulkDeleteVendorProductsStatus: {
          loading: false,
          success: true,
          error: null,
        },
      })
      if (callback) {
        callback()
      }
    } catch (error) {
      set({
        bulkDeleteVendorProductsStatus: {
          loading: false,
          success: false,
          error: error as Error,
        },
      })
    } finally {
      set({
        bulkDeleteVendorProductsStatus: {
          loading: false,
          success: false,
          error: null,
        },
      })
    }
  },
})

const createSaveDataFromPendingItem = <OutputType>(
  inputObj: Partial<ExpandedStoreVendorProduct>,
) => {
  const propList = [
    'barcode',
    'case_cost',
    'case_size',
    'description',
    'each_cost',
    'item_code',
    'lb_cost',
    'store_vendor_id',
    'store_product_id',
  ]

  const generatedObejct = propList.reduce((output, current) => {
    let currentConvertKey = camelCase(current)
    if (Object.prototype.hasOwnProperty.call(inputObj, current)) {
      if (current === 'store_product_id') {
        currentConvertKey = 'storeProductID'
      }
      if (current === 'store_vendor_id') {
        currentConvertKey = 'storeVendorID'
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[currentConvertKey] =
        inputObj[current as keyof Partial<ExpandedStoreVendorProduct>] === '' &&
        current !== 'item_code' &&
        current !== 'description'
          ? null
          : inputObj[current as keyof Partial<ExpandedStoreVendorProduct>]
    }

    /*
    NOTE: store_vendor_id is a derived property from thte store_vendor property
    so it makes sense to handle it as a special case here
    **/
    if (inputObj.store_vendor && current === 'store_vendor_id') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output['storeVendorID'] = inputObj.store_vendor.id
    }
    return output
  }, {} as OutputType)
  return generatedObejct
}

export const getPopulatedUOMType = (
  product: ExpandedStoreVendorProduct,
): VendorProductUOM => {
  const mostRecentCostChange = findMostRecentCostChangeTimetamp(product)

  if (mostRecentCostChange) {
    let selectedUOMType: VendorProductUOM = 'CASE'

    switch (mostRecentCostChange) {
      case 'each_cost_start_time':
        selectedUOMType = 'EACH'
        break
      case 'lb_cost_start_time':
        selectedUOMType = 'LB'
        break
      case 'case_cost_start_time':
        selectedUOMType = 'CASE'
        break
    }

    return selectedUOMType
  }

  if (product.case_cost) {
    return 'CASE'
  }

  if (product.lb_cost) {
    return 'LB'
  }

  if (product.each_cost) {
    return 'EACH'
  }
  return 'CASE'
}

export const findMostRecentCostChangeTimetamp = (
  inputObject: Partial<ExpandedStoreVendorProduct>,
) => {
  const propertyKeys: (keyof ExpandedStoreVendorProduct)[] = [
    'each_cost_start_time',
    'lb_cost_start_time',
    'case_cost_start_time',
  ]
  let mostRecentTime: number | null = null
  let mostRecentKey: string | null = null

  propertyKeys.forEach((key) => {
    const timestamp = inputObject[key] as string | null

    if (
      timestamp &&
      timestamp !== null &&
      !isNaN(new Date(timestamp).getTime())
    ) {
      const date = new Date(timestamp)

      if (mostRecentTime === null || date.getTime() > mostRecentTime) {
        mostRecentTime = date.getTime()
        mostRecentKey = key
      }
    }
  })

  return mostRecentKey
}
