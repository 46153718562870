/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum StoreVendorProductDuplicateType {
  DUPLICATE_DESCRIPTION = 'DUPLICATE_DESCRIPTION',
  DUPLICATE_ITEM_CODE = 'DUPLICATE_ITEM_CODE',
  DUPLICATE_STORE_PRODUCT = 'DUPLICATE_STORE_PRODUCT',
}
