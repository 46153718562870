import { Decimal } from 'decimal.js'
import { toDecimal } from '../../../utils/currency'

export const calculateCostChangeRowMargin: (
  data: {
    eachCost?: string
    lbCost?: string
    currEachCost?: string
    currLbCost?: string
    soldByWeight?: string
    retailPrice?: string
    matchID?: string
  },
  showPreviousMargin?: boolean,
) => Decimal | null = (data, showPreviousMargin = false) => {
  const cost = data?.eachCost ?? data?.lbCost

  const currCostRaw = data?.currEachCost ?? data?.currLbCost

  if (!cost || !data.retailPrice || !data.matchID) return null
  const unitCost = toDecimal(cost)
  const currCost = currCostRaw ? toDecimal(currCostRaw) : null
  const retailPriceNum = toDecimal(data.retailPrice)

  if (showPreviousMargin && currCost) {
    const previousMargin = retailPriceNum
      ?.minus(currCost)
      .dividedBy(retailPriceNum)
      .times(100)
    return previousMargin as Decimal
  }

  if (!unitCost) return null

  const margin =
    retailPriceNum?.minus(unitCost)?.dividedBy(retailPriceNum) ?? null

  if (!unitCost?.equals(currCost ?? -1) && margin) {
    return margin?.times(100) as Decimal
  }
  return null
}
