import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import {
  Menu as ReachMenu,
  MenuButton,
  MenuItem as ReachMenuItem,
  MenuItemProps as ReachMenuItemProps,
  MenuItems,
  MenuLink as ReachMenuLink,
  MenuLinkProps as ReachMenuLinkProps,
  MenuPopover,
} from '@reach/menu-button'

import {
  positionDefault,
  positionMatchWidth,
  positionRight,
} from '@reach/popover'

import { Button, ButtonProps } from '../ButtonNext'
import Card, { CardProps } from '../Card'
import MenuItem, { MenuItemProps } from '../MenuItem'
import Spacer from '../Spacer'

import { Size, sizing, spacing } from '../tokens'

const StyledMenuButton = styled(MenuButton)`
  padding: ${spacing.input.base};
`

const StyledMenuPopover = styled(MenuPopover)<{ $size: CardProps['size'] }>`
  max-width: ${({ $size, position }) =>
    $size != null
      ? sizing.container[$size as Size]
      : position !== positionMatchWidth
        ? 'auto'
        : ''};
  width: ${({ $size, position }) =>
    $size != null
      ? '100% !important'
      : position !== positionMatchWidth
        ? 'auto !important'
        : ''};
  z-index: 1000;
`

const StyledCard = styled(Card)`
  overflow: hidden;
  width: ${({ size }) => (size === 'base' ? 'fit-content' : '100%')};
`

const StyledMenuItems = styled(MenuItems)`
  width: 100%;
`

type MenuLinkProps = Omit<LinkProps, 'to'> & MenuItemProps & { to: string }

const StyledMenuLink = styled(
  forwardRef<HTMLAnchorElement, MenuLinkProps>(function MenuLink(
    { className, ...props }: MenuLinkProps,
    ref,
  ) {
    return (
      <ReactRouterLink className={className} to={props.to} ref={ref}>
        <MenuItem {...props}>{props.children}</MenuItem>
      </ReactRouterLink>
    )
  }),
)<MenuLinkProps>`
  text-decoration: none;
`

type MenuItem = {
  icon?: React.ReactNode
  label: React.ReactNode
  menuItemProps?: Omit<
    Omit<ReachMenuItemProps, 'onSelect'> & MenuItemProps,
    'children'
  >
  withoutRoute?: boolean
}

type MenuLink = {
  icon?: React.ReactNode
  label: React.ReactNode
  menuLinkProps?: Omit<
    Omit<ReachMenuLinkProps, 'onSelect'> & MenuItemProps,
    'children'
  >
  to: string
  withoutRoute?: boolean
}

type MenuPosition = 'default' | 'right' | 'matchWidth'

type MenuProps = React.HTMLAttributes<HTMLDivElement> & {
  buttonProps?: ButtonProps
  links: (MenuItem | MenuLink)[]
  popupProps?: CardProps
  position?: MenuPosition
  useClickable?: boolean
}

const defaultProps: Partial<MenuProps> = {
  buttonProps: {},
  className: '',
  links: [],
  popupProps: {},
  position: 'default',
  useClickable: false,
}

/**
 * @deprecated Use <MenuNext> instead
 */
const StyledMenu = styled(
  forwardRef<HTMLButtonElement, MenuProps>(function Menu(
    {
      buttonProps,
      children,
      links,
      popupProps,
      position,
      useClickable,
    }: MenuProps,
    ref,
  ) {
    const composedButtonProps = useClickable
      ? { ...buttonProps, asClickable: true }
      : buttonProps

    return (
      <ReachMenu>
        {({ isExpanded }) => (
          <>
            <StyledMenuButton
              {...composedButtonProps}
              forwardedAs={Button}
              ref={ref}
            >
              {children}
            </StyledMenuButton>
            {isExpanded && (
              <StyledMenuPopover
                $size={popupProps?.size}
                position={
                  position === 'default'
                    ? positionDefault
                    : position === 'right'
                      ? positionRight
                      : position === 'matchWidth'
                        ? positionMatchWidth
                        : positionDefault
                }
              >
                <Spacer size="small" />
                <StyledCard {...popupProps} noPadding>
                  <StyledMenuItems>
                    {links.map(
                      ({ icon, label, withoutRoute, ...otherProps }) =>
                        withoutRoute ? (
                          <ReachMenuItem
                            as={MenuItem}
                            icon={icon}
                            key={`menu-link-${label}`}
                            onSelect={
                              (otherProps as MenuItem).menuItemProps
                                ?.onSelect ||
                              (() => {
                                void 0
                              })
                            }
                            {...(otherProps as MenuItem).menuItemProps}
                          >
                            {label}
                          </ReachMenuItem>
                        ) : (
                          <ReachMenuLink
                            as={StyledMenuLink}
                            icon={icon}
                            key={`menu-link-${label}`}
                            to={(otherProps as MenuLink).to}
                            {...(otherProps as MenuLink).menuLinkProps}
                          >
                            {label}
                          </ReachMenuLink>
                        ),
                    )}
                  </StyledMenuItems>
                </StyledCard>
                <Spacer size="small" />
              </StyledMenuPopover>
            )}
          </>
        )}
      </ReachMenu>
    )
  }),
)``

StyledMenu.displayName = 'Menu'
StyledMenu.defaultProps = defaultProps

export type { MenuProps, MenuLink }
export { defaultProps as MenuDefaultProps }
export default StyledMenu
