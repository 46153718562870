/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum StoreFileType {
  PRODUCT_LIST = 'PRODUCT_LIST',
  VENDOR_COSTS = 'VENDOR_COSTS',
  VENDOR_CATALOG = 'VENDOR_CATALOG',
  VENDOR_INVOICE = 'VENDOR_INVOICE',
  STORE_DEPARTMENTS = 'STORE_DEPARTMENTS',
  STORE_VENDORS = 'STORE_VENDORS',
  WIC = 'WIC',
}
