import React from 'react'

import { FlexNext as Flex, TextNext as Text } from '@vori/gourmet-components'

import { ENV_PROD } from '@vori/dashboard-env'
import { getFullStorySessionURL } from '@vori/dashboard-integrations/FullStory/getFullStorySessionURL'
import { RestApiError } from '@vori/dashboard-api/error'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { SentryTags } from '@vori/dashboard-constants'

class GlobalErrorBoundary extends React.Component<
  object,
  { hasError: boolean; chunkLoadError: boolean }
> {
  constructor(props: object) {
    super(props)
    this.state = { hasError: false, chunkLoadError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (ENV_PROD && !(error instanceof RestApiError)) {
      captureException(
        (scope) => {
          scope.setContext('Global error boundary triggered', { errorInfo })
          const sessionURL = getFullStorySessionURL()

          if (sessionURL) {
            scope.setContext('fullstorySession', { url: sessionURL })
          }

          return error
        },
        {
          sentryTag: SentryTags.GLOBAL_ERROR_BOUNDARY,
        },
      )
    }

    if (
      error?.message.search('Failed to fetch dynamically imported module') !==
      -1
    ) {
      this.setState({ chunkLoadError: true })
      window.location.reload()
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError && !this.state.chunkLoadError) {
      return (
        <Flex
          style={{ height: '100vh' }}
          alignItems="center"
          flex="1 0 auto"
          justifyContent="center"
        >
          <Text size="text-xl" variant="negative">
            Something went wrong. Please try again
          </Text>
        </Flex>
      )
    }

    return this.props.children
  }
}

export { GlobalErrorBoundary }
