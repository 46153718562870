/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  APLRevisionRowCountDTO,
  APLRevisionRowPaginatedQueryDTO,
  APLRevisionRowQueryBodyDTO,
  APLRevisionRowSelectionDeleteSuccessDTO,
  ConflictGroupsResponseDTO,
  DataFrameConfigDto,
  GetInitialProductRowsParams,
  GetProductRowsParams,
  GetTransactionSummaryParams,
  PreviewProductsTransactionParams,
  RevisionRowListDTO,
  RevisionStateOverridesInputDTO,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getConflicts = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ConflictGroupsResponseDTO>(
    { url: `/apl-revisions/${revisionID}/rows/conflicts`, method: 'GET' },
    options,
  )
}

export const getGetConflictsKey = (revisionID: string) =>
  [`/apl-revisions/${revisionID}/rows/conflicts`] as const

export type GetConflictsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConflicts>>
>
export type GetConflictsQueryError = ErrorType<unknown>

export const useGetConflicts = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getConflicts>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetConflictsKey(revisionID) : null))
  const swrFn = () => getConflicts(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const previewRevisionRows = (
  revisionID: string,
  aPLRevisionRowQueryBodyDTO: APLRevisionRowQueryBodyDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowPaginatedQueryDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/preview`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aPLRevisionRowQueryBodyDTO,
    },
    options,
  )
}

export const getPreviewRevisionRowsMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: APLRevisionRowQueryBodyDTO },
  ): Promise<APLRevisionRowPaginatedQueryDTO> => {
    return previewRevisionRows(revisionID, arg, options)
  }
}
export const getPreviewRevisionRowsMutationKey = (revisionID: string) =>
  [`/apl-revisions/${revisionID}/rows/preview`] as const

export type PreviewRevisionRowsMutationResult = NonNullable<
  Awaited<ReturnType<typeof previewRevisionRows>>
>
export type PreviewRevisionRowsMutationError = ErrorType<unknown>

export const usePreviewRevisionRows = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof previewRevisionRows>>,
      TError,
      Key,
      APLRevisionRowQueryBodyDTO,
      Awaited<ReturnType<typeof previewRevisionRows>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getPreviewRevisionRowsMutationKey(revisionID)
  const swrFn = getPreviewRevisionRowsMutationFetcher(
    revisionID,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const countRevisionRows = (
  revisionID: string,
  aPLRevisionRowQueryBodyDTO: APLRevisionRowQueryBodyDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowCountDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/preview/count`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aPLRevisionRowQueryBodyDTO,
    },
    options,
  )
}

export const getCountRevisionRowsMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: APLRevisionRowQueryBodyDTO },
  ): Promise<APLRevisionRowCountDTO> => {
    return countRevisionRows(revisionID, arg, options)
  }
}
export const getCountRevisionRowsMutationKey = (revisionID: string) =>
  [`/apl-revisions/${revisionID}/rows/preview/count`] as const

export type CountRevisionRowsMutationResult = NonNullable<
  Awaited<ReturnType<typeof countRevisionRows>>
>
export type CountRevisionRowsMutationError = ErrorType<unknown>

export const useCountRevisionRows = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof countRevisionRows>>,
      TError,
      Key,
      APLRevisionRowQueryBodyDTO,
      Awaited<ReturnType<typeof countRevisionRows>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getCountRevisionRowsMutationKey(revisionID)
  const swrFn = getCountRevisionRowsMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getProductRows = (
  revisionID: string,
  params: GetProductRowsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowPaginatedQueryDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/products`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getGetProductRowsKey = (
  revisionID: string,
  params: GetProductRowsParams,
) =>
  [
    `/apl-revisions/${revisionID}/rows/products`,
    ...(params ? [params] : []),
  ] as const

export type GetProductRowsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductRows>>
>
export type GetProductRowsQueryError = ErrorType<unknown>

export const useGetProductRows = <TError = ErrorType<unknown>>(
  revisionID: string,
  params: GetProductRowsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getProductRows>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetProductRowsKey(revisionID, params) : null))
  const swrFn = () => getProductRows(revisionID, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getInitialProductRows = (
  revisionID: string,
  params: GetInitialProductRowsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowPaginatedQueryDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/products/initial`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getGetInitialProductRowsKey = (
  revisionID: string,
  params: GetInitialProductRowsParams,
) =>
  [
    `/apl-revisions/${revisionID}/rows/products/initial`,
    ...(params ? [params] : []),
  ] as const

export type GetInitialProductRowsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInitialProductRows>>
>
export type GetInitialProductRowsQueryError = ErrorType<unknown>

export const useGetInitialProductRows = <TError = ErrorType<unknown>>(
  revisionID: string,
  params: GetInitialProductRowsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getInitialProductRows>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetInitialProductRowsKey(revisionID, params) : null))
  const swrFn = () => getInitialProductRows(revisionID, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const previewProductsTransaction = (
  revisionID: string,
  params: PreviewProductsTransactionParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowPaginatedQueryDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/products/transaction/preview`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getPreviewProductsTransactionKey = (
  revisionID: string,
  params: PreviewProductsTransactionParams,
) =>
  [
    `/apl-revisions/${revisionID}/rows/products/transaction/preview`,
    ...(params ? [params] : []),
  ] as const

export type PreviewProductsTransactionQueryResult = NonNullable<
  Awaited<ReturnType<typeof previewProductsTransaction>>
>
export type PreviewProductsTransactionQueryError = ErrorType<unknown>

export const usePreviewProductsTransaction = <TError = ErrorType<unknown>>(
  revisionID: string,
  params: PreviewProductsTransactionParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof previewProductsTransaction>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getPreviewProductsTransactionKey(revisionID, params) : null)
  const swrFn = () =>
    previewProductsTransaction(revisionID, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const deleteRowSelection = (
  revisionID: string,
  dataFrameConfigDto: DataFrameConfigDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowSelectionDeleteSuccessDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/selection`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: dataFrameConfigDto,
    },
    options,
  )
}

export const getDeleteRowSelectionMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: DataFrameConfigDto },
  ): Promise<APLRevisionRowSelectionDeleteSuccessDTO> => {
    return deleteRowSelection(revisionID, arg, options)
  }
}
export const getDeleteRowSelectionMutationKey = (revisionID: string) =>
  [`/apl-revisions/${revisionID}/rows/selection`] as const

export type DeleteRowSelectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRowSelection>>
>
export type DeleteRowSelectionMutationError = ErrorType<unknown>

export const useDeleteRowSelection = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteRowSelection>>,
      TError,
      Key,
      DataFrameConfigDto,
      Awaited<ReturnType<typeof deleteRowSelection>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteRowSelectionMutationKey(revisionID)
  const swrFn = getDeleteRowSelectionMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const stateOverrideRows = (
  revisionID: string,
  revisionStateOverridesInputDTO: RevisionStateOverridesInputDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionRowListDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/state-overrides`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: revisionStateOverridesInputDTO,
    },
    options,
  )
}

export const getStateOverrideRowsMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: RevisionStateOverridesInputDTO },
  ): Promise<RevisionRowListDTO> => {
    return stateOverrideRows(revisionID, arg, options)
  }
}
export const getStateOverrideRowsMutationKey = (revisionID: string) =>
  [`/apl-revisions/${revisionID}/rows/state-overrides`] as const

export type StateOverrideRowsMutationResult = NonNullable<
  Awaited<ReturnType<typeof stateOverrideRows>>
>
export type StateOverrideRowsMutationError = ErrorType<unknown>

export const useStateOverrideRows = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof stateOverrideRows>>,
      TError,
      Key,
      RevisionStateOverridesInputDTO,
      Awaited<ReturnType<typeof stateOverrideRows>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getStateOverrideRowsMutationKey(revisionID)
  const swrFn = getStateOverrideRowsMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getTransactionSummary = (
  revisionID: string,
  params: GetTransactionSummaryParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionRowPaginatedQueryDTO>(
    {
      url: `/apl-revisions/${revisionID}/rows/transaction/summary`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getGetTransactionSummaryKey = (
  revisionID: string,
  params: GetTransactionSummaryParams,
) =>
  [
    `/apl-revisions/${revisionID}/rows/transaction/summary`,
    ...(params ? [params] : []),
  ] as const

export type GetTransactionSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionSummary>>
>
export type GetTransactionSummaryQueryError = ErrorType<unknown>

export const useGetTransactionSummary = <TError = ErrorType<unknown>>(
  revisionID: string,
  params: GetTransactionSummaryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTransactionSummary>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTransactionSummaryKey(revisionID, params) : null))
  const swrFn = () => getTransactionSummary(revisionID, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
