/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum CustomQuickActionType {
  discount = 'discount',
  item = 'item',
  refund_item = 'refund_item',
  bottle_refund = 'bottle_refund',
  item_modifier = 'item_modifier',
  department_menu = 'department_menu',
  discount_menu = 'discount_menu',
  manual_item_menu = 'manual_item_menu',
  loyalty_menu = 'loyalty_menu',
  variable_weight_menu = 'variable_weight_menu',
}
