/**
 * TypeScript casts the `error` value on the catch block on try/catch/finally
 * statements as `unknown`, so this function will try to return the given
 * `error` value as a typed `Error` when it can, otherwise, it will use the
 * `defaultErrorMessage` value to return a new `Error` instance.
 */
export function getTypedTryCatchError(
  error: unknown,
  defaultErrorMessage?: string,
): Error {
  return error instanceof Error ? error : new Error(defaultErrorMessage)
}
