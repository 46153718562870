import React from 'react'

import { cacheUserData, getCachedUserData, removeCachedUserData } from './utils'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirebaseApp } from '@vori/dashboard-utils'

import {
  AuthErrorSource,
  CurrentUserData,
  CurrentUserDispatchContextValue,
  CurrentUserStateContextValue,
} from './types'

import { useApolloClient } from '@apollo/client'
import { useAppGetCurrentUserLazyQuery } from '@vori/gql-dashboard'
import { useCurrentUserReducer } from './reducer'
import { useDebouncedValue } from '@vori/react-hooks'
import { useListFeatures } from '@vori/dashboard-rest-next/users/users'
import { useLocation } from 'react-router-dom'

import { GATED_FEATURE_IDs, SentryTags } from '@vori/dashboard-constants'
import { USER_FEATURE_FLAGS_CHECK_DEBOUNCE } from './constants'

const CurrentUserStateContext = React.createContext<
  CurrentUserStateContextValue | undefined
>(undefined)

const CurrentUserDispatchContext = React.createContext<
  CurrentUserDispatchContextValue | undefined
>(undefined)

/**
 * Returns the current `value` for the parent `<CurrentUserStateContext>`,
 * if any.
 */
export function useCurrentUserState(): CurrentUserStateContextValue {
  const value = React.useContext(CurrentUserStateContext)

  if (value === undefined) {
    throw new Error(
      'The `useCurrentUserStateContext` hook must be used within `<CurrentUserProvider>`',
    )
  }

  return value
}

/**
 * Returns the current `value` for the parent `<CurrentUserDispatchContext>`,
 * if any.
 */
export function useCurrentUserDispatch(): CurrentUserDispatchContextValue {
  const value = React.useContext(CurrentUserDispatchContext)

  if (value === undefined) {
    throw new Error(
      'The `useCurrentUserDispatchContext` hook must be used within `<CurrentUserProvider>`',
    )
  }

  return value
}

/**
 * A React provider component that exposes the current user state and dispatch
 * function all children components.
 */
export function CurrentUserProvider({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  const apolloClient = useApolloClient()
  const cachedUserData = getCachedUserData()
  const location = useLocation()

  /**
   * We want to compare the current URL with the previous one to know if we need
   * to re-fetch the current user's feature flags, but some routes will redirect
   * the user, e.g. going to `/promotions` will redirect you to `/promotions/status/Active`.
   * To prevent fetching the feature flags twice for these redirecting routes, we
   * debounce this check by storing the current URL after a delay.
   */
  const debouncedPathname = useDebouncedValue(
    location.pathname,
    USER_FEATURE_FLAGS_CHECK_DEBOUNCE,
  )

  const [state, dispatch] = useCurrentUserReducer(null)

  const { mutate: reloadFeatureFlags } = useListFeatures(
    {
      feature_ids: GATED_FEATURE_IDs,
    },
    {
      swr: {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
        onSuccess: (result) => {
          dispatch({
            type: 'userV2/featureFlagsUpdated',
            payload: {
              featureFlags: result.feature_ids,
            },
          })
        },
      },
    },
  )

  React.useEffect(() => {
    if (!state.user.authToken) {
      return
    }

    // Reload user state's feature flags whenever user navigates to another
    // page in the app.
    reloadFeatureFlags()
  }, [debouncedPathname, reloadFeatureFlags, state.user.authToken])

  const [getCurrentUserData] = useAppGetCurrentUserLazyQuery({
    // Since we are caching the user's data on localStorage, we
    // use `network-only` policy to ensure we are fetching the user's data
    // after login from the API and not from Apollo's cache.
    fetchPolicy: 'network-only',
    variables: { gatedFeatures: GATED_FEATURE_IDs },
  })

  React.useEffect(() => {
    const firebaseApp = getFirebaseApp()

    if (!firebaseApp) {
      return
    }

    const auth = getAuth(firebaseApp)

    const unsubscribeFromAuthListener = onAuthStateChanged(
      auth,
      async (user) => {
        dispatch({ type: 'userV2/authenticationStarted' })

        // If `user` is not defined, then `onAuthStateChanged` was triggered
        // due to the user logging out.
        if (!user) {
          // Clear Apollo Client store/cache
          await apolloClient.clearStore()
          // Remove locally cached data from the user that was just logged out.
          localStorage.clear()
          sessionStorage.clear()
          // Clear user data from memory from the user that was just logged out.
          dispatch({ type: 'userV2/unauthenticated' })
          // Exit early from this effect.
          return
        }

        try {
          // If `onAuthStateChanged` gets triggered as a result of the token being
          // refreshed, then we don't want to fetch the user's data again.
          const userData = cachedUserData
            ? cachedUserData
            : state.user.state.isLoggedIn
              ? state.user.data
              : (await getCurrentUserData()).data?.me?.user

          // If, for whatever reason, we don't have locally cached user data and
          // the request for fetching the user's data doesn't return anything
          // for this user, we clear any data that we might have cached locally
          // and stored in memory which will then trigger a redirect to the login page.
          if (!userData) {
            captureException(
              (scope) => {
                scope.addBreadcrumb({
                  category: 'auth',
                  message: "getCurrentUserData succeeded but it's empty",
                  level: 'error',
                  type: 'error',
                  data: {
                    firebaseUser: user,
                  },
                })
                return new Error('User data not found')
              },
              {
                sentryTag: `${SentryTags.AUTH_PREFIX}${AuthErrorSource.AUTH_STATE_CHANGED}`,
              },
            )

            // Remove locally cached data from the user that encountered an
            // authentication error.
            removeCachedUserData()

            // Clear user data from memory from the user that encountered an
            // authentication error.
            dispatch({
              type: 'userV2/authenticationFailed',
              payload: {
                error: new Error('User data not found'),
              },
            })

            // Exit early from this effect.
            return
          }

          // If there is no auth token stored, then the user is logging in, so
          // we store the user's data on localStorage to prevent hitting the API
          // on subsequent visits.
          if (!state.user.authToken) {
            cacheUserData(userData as CurrentUserData)
          }

          // Get the latest authentication token from the currently logged in
          // user, to keep in memory so we can use it in other places, e.g.
          // HTTP headers.
          const authToken = await user.getIdToken()

          // Store the current user's data in memory.
          dispatch({
            type: 'userV2/authenticated',
            payload: {
              authToken,
              data: userData,
            },
          })
        } catch (error) {
          // If the request for fetching the user's data fails, we clear anything
          // that we might have cached locally and stored in memory which will
          // then trigger a redirect to the login page.
          removeCachedUserData()

          captureException(
            (scope) => {
              scope.addBreadcrumb({
                category: 'auth',
                message:
                  "User authentication failed while trying to fetch the user's data via the GraphQL API (AppGetCurrentUserQuery)",
                level: 'debug',
                type: 'debug',
                data: {
                  firebaseUser: user,
                },
              })

              return error instanceof Error
                ? error
                : new Error('User data fetch failed')
            },
            {
              sentryTag: `${SentryTags.AUTH_PREFIX}${AuthErrorSource.AUTH_STATE_CHANGED}`,
            },
          )

          dispatch({
            type: 'userV2/authenticationFailed',
            payload: {
              error:
                error instanceof Error
                  ? error
                  : new Error('Firebase authentication failed'),
            },
          })
        }
      },
    )

    return () => {
      // Unsubscribe from listening to user authentication changes when the
      // Provider component unmounts.
      unsubscribeFromAuthListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CurrentUserStateContext.Provider value={state}>
      <CurrentUserDispatchContext.Provider value={dispatch}>
        {children}
      </CurrentUserDispatchContext.Provider>
    </CurrentUserStateContext.Provider>
  )
}
