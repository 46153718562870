/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Arguments, Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  StoreFileArtifactDTO,
  StoreFileArtifactResponseDTO,
  StoreFileResponseDTO,
  StoreFileSignedURLResponseDTO,
  StoreFileUploadDTO,
  StoreFilesControllerCreateArtifactParams,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'
import { customFormData } from '../../custom-form-data'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const storeFilesControllerCreateArtifact = (
  params: StoreFilesControllerCreateArtifactParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreFileArtifactDTO>(
    { url: `/store-files/artifacts`, method: 'POST', params },
    options,
  )
}

export const getStoreFilesControllerCreateArtifactMutationFetcher = (
  params: StoreFilesControllerCreateArtifactParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<StoreFileArtifactDTO> => {
    return storeFilesControllerCreateArtifact(params, options)
  }
}
export const getStoreFilesControllerCreateArtifactMutationKey = (
  params: StoreFilesControllerCreateArtifactParams,
) => [`/store-files/artifacts`, ...(params ? [params] : [])] as const

export type StoreFilesControllerCreateArtifactMutationResult = NonNullable<
  Awaited<ReturnType<typeof storeFilesControllerCreateArtifact>>
>
export type StoreFilesControllerCreateArtifactMutationError = ErrorType<unknown>

export const useStoreFilesControllerCreateArtifact = <
  TError = ErrorType<unknown>,
>(
  params: StoreFilesControllerCreateArtifactParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof storeFilesControllerCreateArtifact>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof storeFilesControllerCreateArtifact>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ??
    getStoreFilesControllerCreateArtifactMutationKey(params)
  const swrFn = getStoreFilesControllerCreateArtifactMutationFetcher(
    params,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const storeFilesControllerFindAll = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreFileResponseDTO>(
    { url: `/store-files/files`, method: 'GET' },
    options,
  )
}

export const getStoreFilesControllerFindAllKey = () =>
  [`/store-files/files`] as const

export type StoreFilesControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof storeFilesControllerFindAll>>
>
export type StoreFilesControllerFindAllQueryError = ErrorType<unknown>

export const useStoreFilesControllerFindAll = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof storeFilesControllerFindAll>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getStoreFilesControllerFindAllKey() : null))
  const swrFn = () => storeFilesControllerFindAll(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getStoreFileSignedURL = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreFileSignedURLResponseDTO>(
    { url: `/store-files/files/signed_url`, method: 'GET' },
    options,
  )
}

export const getGetStoreFileSignedURLKey = () =>
  [`/store-files/files/signed_url`] as const

export type GetStoreFileSignedURLQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreFileSignedURL>>
>
export type GetStoreFileSignedURLQueryError = ErrorType<unknown>

export const useGetStoreFileSignedURL = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getStoreFileSignedURL>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetStoreFileSignedURLKey() : null))
  const swrFn = () => getStoreFileSignedURL(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const storeFilesControllerGetRevisionArtifacts = (
  fileID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreFileArtifactResponseDTO>(
    { url: `/store-files/${fileID}/artifacts`, method: 'GET' },
    options,
  )
}

export const getStoreFilesControllerGetRevisionArtifactsKey = (
  fileID: string,
) => [`/store-files/${fileID}/artifacts`] as const

export type StoreFilesControllerGetRevisionArtifactsQueryResult = NonNullable<
  Awaited<ReturnType<typeof storeFilesControllerGetRevisionArtifacts>>
>
export type StoreFilesControllerGetRevisionArtifactsQueryError =
  ErrorType<unknown>

export const useStoreFilesControllerGetRevisionArtifacts = <
  TError = ErrorType<unknown>,
>(
  fileID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof storeFilesControllerGetRevisionArtifacts>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!fileID
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getStoreFilesControllerGetRevisionArtifactsKey(fileID) : null)
  const swrFn = () =>
    storeFilesControllerGetRevisionArtifacts(fileID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const uploadStoreFiles = (
  storeID: string,
  storeFileUploadDTO: StoreFileUploadDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  const formData = customFormData(storeFileUploadDTO)
  return customAxiosInstance<StoreFileResponseDTO>(
    {
      url: `/store-files/${storeID}/files/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

export const getUploadStoreFilesMutationFetcher = (
  storeID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: StoreFileUploadDTO },
  ): Promise<StoreFileResponseDTO> => {
    return uploadStoreFiles(storeID, arg, options)
  }
}
export const getUploadStoreFilesMutationKey = (storeID: string) =>
  [`/store-files/${storeID}/files/upload`] as const

export type UploadStoreFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadStoreFiles>>
>
export type UploadStoreFilesMutationError = ErrorType<unknown>

export const useUploadStoreFiles = <TError = ErrorType<unknown>>(
  storeID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof uploadStoreFiles>>,
      TError,
      Key,
      StoreFileUploadDTO,
      Awaited<ReturnType<typeof uploadStoreFiles>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUploadStoreFilesMutationKey(storeID)
  const swrFn = getUploadStoreFilesMutationFetcher(storeID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
