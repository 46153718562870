import { post } from '@vori/dashboard-api/post'
import {
  APLRevisionDTO,
  CreateInvoiceRevisionDto,
  DataFrameConfigDto,
  InvoiceRevisionStatus,
} from '@vori/dashboard-rest-next/schemas'

export const createRevision = async (
  filterModel: DataFrameConfigDto['filterModel'],
) => {
  return post<APLRevisionDTO, CreateInvoiceRevisionDto>(
    `/v1/admin/invoices/revisions`,
    {
      expressions: {
        invoiceNumber: '$val',
        storeID: '$val',
        vendorID: '$val',
        storeVendorID: '$val',
        vendorName: '$val',
        vendorAddress: '$val',
        vendorPlaceID: '$val',
        billToName: '$val',
        billToAddress: '$val',
        billToPlaceID: '$val',
        shipToName: '$val',
        shipToAddress: '$val',
        shipToPlaceID: '$val',
      },
      selection: {
        startRow: 0,
        endRow: 1000000, // arbitrarily high number
        rowGroupCols: [],
        valueCols: [],
        pivotCols: [],
        pivotMode: false,
        groupKeys: [],
        filterModel: filterModel,
        sortModel: [],
      },
      status: InvoiceRevisionStatus.STAGED,
    },
  )
}

export const quickViewSelectorOptions = [
  {
    value: {
      columns: [
        'row_selection',
        'storeID',
        'vendorID',
        'id',
        'invoiceNumber',
        'filePath',
        'total',
        'term',
        'billingDate',
        'status_v3',
        'action',
      ],
      leftPinned: ['row_selection', 'storeID', 'vendorID', 'id', 'filePath'],
      rightPinned: ['status_v3', 'action'],
      filterModel: {},
    },
    label: 'Invoice Header',
    description:
      'Edit basic header metadata. Ex. total, net terms, invoiceNumber, etc',
  },
  {
    value: {
      columns: [
        'row_selection',
        'id',
        'storeID',
        'shipToName',
        'shipToAddress',
        'shipToPlaceID',
        'billToName',
        'billToAddress',
        'billToPlaceID',
        'vendorID',
        'vendorPlaceID',
        'storeVendorID',
        'vendorNameRaw',
        'vendorAddress',
        'invoiceNumber',
        'filePath',
        'status_v3',
        'action',
      ],
      leftPinned: ['row_selection', 'id', 'filePath'],
      rightPinned: ['status_v3', 'action'],
      filterModel: {},
    },
    label: 'Routing',
    description: 'Route Invoices to Vendors & Stores',
  },
  {
    value: {
      columns: [
        'row_selection',
        'id',
        'storeID',
        'shipToName',
        'shipToAddress',
        'billToName',
        'billToAddress',
        'invoiceNumber',
        'shipToPlaceID',
        'billToPlaceID',
        'filePath',
        'status_v3',
        'action',
      ],
      leftPinned: ['row_selection', 'id', 'filePath'],
      rightPinned: ['status_v3', 'action'],
      filterModel: {},
    },
    label: 'Store Routing',
    description: 'Route Invoices to Stores',
  },
  {
    value: {
      columns: [
        'row_selection',
        'id',
        'storeID',
        'vendorID',
        'vendorName',
        'vendorAddress',
        'vendorPlaceID',
        'invoiceNumber',
        'filePath',
        'status_v3',
        'action',
      ],
      leftPinned: ['row_selection', 'id', 'filePath'],
      rightPinned: ['status_v3', 'action'],
      filterModel: {},
    },
    label: 'Vendor Routing',
    description: 'Route Invoices to Vendors',
  },
]

export const defaultAggridState = {
  startRow: 0,
  endRow: 2000,
  rowGroupCols: [],
  valueCols: [],
  pivotCols: [],
  pivotMode: false,
  groupKeys: [],
  sortModel: [],
  filterModel: {},
}
