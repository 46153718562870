/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum OfferBenefitType {
  PERCENTAGE_OFF = 'PERCENTAGE_OFF',
  AMOUNT_OFF = 'AMOUNT_OFF',
  BASKET_AMOUNT_OFF = 'BASKET_AMOUNT_OFF',
  FIXED_PRICE = 'FIXED_PRICE',
  MULTI_BUY = 'MULTI_BUY',
}
