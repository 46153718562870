/**
 * Used to circumvent Jest's limitation with ES modules which Vite depends on.
 * We basically route all environmental variables through this file/module so
 * we can easily mock the file in Jest.
 *
 * @see {@link https://stackoverflow.com/a/74882007}
 */

const {
  PACKAGE_VERSION: ENV_VERSION,
  VITE_COURIER_BRAND_ID: ENV_COURIER_BRAND_ID,
  VITE_COURIER_CLIENT_KEY: ENV_COURIER_CLIENT_KEY,
  VITE_ENVIRONMENT: ENVIRONMENT,
  VITE_FIREBASE_API_KEY: ENV_FIREBASE_API_KEY,
  VITE_FIREBASE_APP_ID: ENV_FIREBASE_APP_ID,
  VITE_FIREBASE_AUTH_DOMAIN: ENV_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_DATABASE_URL: ENV_FIREBASE_DATABASE_URL,
  VITE_FIREBASE_MEASUREMENT_ID: ENV_FIREBASE_MEASUREMENT_ID,
  VITE_FIREBASE_MESSAGING_SENDER_ID: ENV_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_PROJECT_ID: ENV_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_STORAGE_BUCKET: ENV_FIREBASE_STORAGE_BUCKET,
  VITE_FULLSTORY_ORGANIZATION_ID: ENV_FULLSTORY_ORGANIZATION_ID,
  VITE_GAE_REST_ENDPOINT: ENV_GAE_REST_ENDPOINT,
  VITE_GOOGLE_MAPS_API_KEY: ENV_GOOGLE_MAPS_API_KEY,
  VITE_GRAPHQL_ENDPOINT: ENV_GRAPHQL_ENDPOINT,
  VITE_INTERCOM_APP_ID: ENV_INTERCOM_APP_ID,
  VITE_OTEL_ENDPOINT: ENV_OTEL_ENDPOINT,
  VITE_OTEL_TRACE_HEADER_CORS_URL: ENV_OTEL_TRACE_HEADER_CORS_URL,
  VITE_PEXELS_API_KEY: ENV_PEXELS_API_KEY,
  VITE_PLAID_PUBLIC_KEY: ENV_PLAID_PUBLIC_KEY,
  VITE_SEGMENT_WRITE_KEY: ENV_SEGMENT_WRITE_KEY,
  VITE_SENTRY_DSN: ENV_SENTRY_DSN,
  VITE_SENTRY_ENABLE_LOCAL_REPORTING,
  VITE_STRIPE_PUBLISHABLE_KEY: ENV_STRIPE_PUBLISHABLE_KEY,
  VITE_USE_MOCKS: ENV_USE_MOCKS,
} = import.meta.env

const ENV_DEV = ENVIRONMENT === 'development'
const ENV_LOCAL = ENVIRONMENT === 'local'
const ENV_PROD = ENVIRONMENT === 'production'

const ENV_SENTRY_ENABLE_LOCAL_REPORTING =
  VITE_SENTRY_ENABLE_LOCAL_REPORTING === 'true'

export {
  ENVIRONMENT,
  ENV_COURIER_BRAND_ID,
  ENV_COURIER_CLIENT_KEY,
  ENV_DEV,
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_DATABASE_URL,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MESSAGING_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
  ENV_FULLSTORY_ORGANIZATION_ID,
  ENV_GAE_REST_ENDPOINT,
  ENV_GOOGLE_MAPS_API_KEY,
  ENV_GRAPHQL_ENDPOINT,
  ENV_INTERCOM_APP_ID,
  ENV_LOCAL,
  ENV_OTEL_ENDPOINT,
  ENV_OTEL_TRACE_HEADER_CORS_URL,
  ENV_PEXELS_API_KEY,
  ENV_PLAID_PUBLIC_KEY,
  ENV_PROD,
  ENV_SEGMENT_WRITE_KEY,
  ENV_SENTRY_DSN,
  ENV_SENTRY_ENABLE_LOCAL_REPORTING,
  ENV_STRIPE_PUBLISHABLE_KEY,
  ENV_USE_MOCKS,
  ENV_VERSION,
}
