import { CurrentRevisionRow } from '../types'

export const getDefaultUom: (
  data: CurrentRevisionRow,
) => 'LB' | 'CASE' | 'EACH' = (data: CurrentRevisionRow) => {
  if (data?.soldByWeight) {
    if (data?.lbCost) return 'LB'
    if (data?.caseCost) return 'CASE'
    if (data?.eachCost) return 'EACH'
  }
  if (data?.caseCost) return 'CASE'
  if (data?.lbCost) return 'LB'
  if (data?.eachCost) return 'EACH'
  return 'CASE'
}
