/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum CouponUsageType {
  multi_use = 'multi_use',
  single_use = 'single_use',
  once_per_shopper = 'once_per_shopper',
}
