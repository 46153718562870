import { APLRevisionTransactionConfig } from '@vori/dashboard-components/revisions/types'
import {
  aPLRevisionsControllerGetInitialFrameSchema,
  getRevisionLegacy,
} from '@vori/dashboard-rest-next/aplrevision/aplrevision'
import {
  listRevisionCandidate,
  listRevisionScoringStrategy,
} from '@vori/dashboard-rest-next/revisions/revisions'
import {
  APLRevisionDTO,
  APLRevisionDTOExtraMetadata,
  APLRevisionDTOPurchaseOrder,
  APLRevisionSchemaResponseDTOSchema,
  RevisionCandidateDto,
  RevisionScoringStrategyDto,
  StoreFileArtifactResponseDTO,
  StoreFileDTO,
} from '@vori/dashboard-rest-next/schemas'
import { RevisionTypeEnum } from '@vori/dashboard-rest-next/schemas/revisionTypeEnum'
import {
  getStoreFileSignedURL,
  storeFilesControllerGetRevisionArtifacts,
} from '@vori/dashboard-rest-next/store-files/store-files'
import { StateCreator } from 'zustand'
export type RevisionSlice = {
  documentUrl: string
  documentViewerOpen: boolean
  storeFile: StoreFileDTO | null
  candidates: RevisionCandidateDto[]
  scoringStrategy: RevisionScoringStrategyDto[] | null
  artifacts: StoreFileArtifactResponseDTO
  initialized: boolean
  purchase_order: APLRevisionDTOPurchaseOrder | null
  revision_type: RevisionTypeEnum | null
  extra_metadata: APLRevisionDTOExtraMetadata | null
  initialSchema: APLRevisionSchemaResponseDTOSchema | null
  transactions: APLRevisionTransactionConfig[]

  setDocumentUrl: (documentUrl: string) => void
  setDocumentViewerOpen: (documentViewerOpen: boolean) => void

  // Individual fetchers
  fetchRevisionData: (revisionID: string) => Promise<APLRevisionDTO | null>
  fetchCandidates: (revisionID: string) => Promise<RevisionCandidateDto[]>
  fetchScoringStrategy: () => Promise<RevisionScoringStrategyDto[]>
  fetchArtifacts: (
    storeFileId?: string,
  ) => Promise<StoreFileArtifactResponseDTO>
  fetchInitialSchema: (revisionID: string) => Promise<void>
  fetchStoreFileSignedURL: (storeFilePath?: string) => Promise<void>

  // Initialization function
  initialize: (revisionID: string, forceRefresh?: boolean) => Promise<void>
}
export const createRevisionSlice: StateCreator<RevisionSlice> = (set, get) => ({
  documentUrl: '',
  documentViewerOpen: false,
  initialized: false,
  revision_type: null,
  purchase_order: null,
  storeFile: null,
  candidates: [],
  scoringStrategy: [],
  artifacts: {
    artifacts: [],
    directory: '',
  },
  extra_metadata: null,
  initialSchema: null,
  transactions: [],

  setDocumentViewerOpen: (documentViewerOpen: boolean) => {
    set({ documentViewerOpen })
  },
  setDocumentUrl: (documentUrl: string) => {
    set({ documentUrl })
  },

  fetchRevisionData: async (revisionID: string) => {
    try {
      const revision = await getRevisionLegacy(revisionID)
      set({
        revision_type: revision.revision_type,
        purchase_order: revision.purchase_order,
        storeFile: revision.store_file,
        extra_metadata: revision.extra_metadata,
      })
      return revision
    } catch (error) {
      console.error('Error fetching revision data:', error)
      return null
    }
  },

  fetchCandidates: async (revisionID: string) => {
    try {
      const candidates = await listRevisionCandidate({
        revision_id: revisionID,
      })
      set({ candidates })
      return candidates
    } catch (error) {
      console.error('Error fetching revision candidates:', error)
      set({ candidates: [] })
      return []
    }
  },

  fetchScoringStrategy: async () => {
    try {
      const scoringStrategy = await listRevisionScoringStrategy()
      set({ scoringStrategy })
      return scoringStrategy
    } catch (error) {
      console.error('Error fetching revision scoring strategy:', error)
      set({ scoringStrategy: null })
      return []
    }
  },

  fetchArtifacts: async (storeFileId?: string) => {
    if (!storeFileId) {
      console.warn('No store file ID provided, skipping artifact fetch')
      return {
        artifacts: [],
        directory: '',
      }
    }
    try {
      const artifacts =
        await storeFilesControllerGetRevisionArtifacts(storeFileId)
      set({ artifacts })
      return artifacts
    } catch (error) {
      console.error('Error fetching revision artifacts:', error)
      set({
        artifacts: {
          artifacts: [],
          directory: '',
        },
      })
      return {
        artifacts: [],
        directory: '',
      }
    }
  },

  fetchInitialSchema: async (revisionID: string) => {
    try {
      const initialSchema =
        await aPLRevisionsControllerGetInitialFrameSchema(revisionID)
      set({ initialSchema: initialSchema?.schema || null })
    } catch (error) {
      console.error('Error fetching initial schema:', error)
      set({ initialSchema: null })
    }
  },

  fetchStoreFileSignedURL: async (storeFilePath?: string) => {
    if (!storeFilePath) {
      console.warn('No store file path provided, skipping signedURL fetch')
      return
    }
    try {
      const signedURL = await getStoreFileSignedURL({
        params: {
          filePath: storeFilePath,
        },
      })
      set({ documentUrl: signedURL.url })
    } catch (error) {
      console.error('Error fetching store file signed URL:', error)
    }
  },

  initialize: async (revisionID: string, forceRefresh = false) => {
    const initialized = get().initialized
    if (initialized && !forceRefresh) {
      return
    }

    set({ initialized: true })

    try {
      await get().fetchRevisionData(revisionID)
      const storeFile = get().storeFile
      const storeFileId = storeFile?.id || get().storeFile?.id

      await Promise.all([
        get().fetchCandidates(revisionID),
        get().fetchScoringStrategy(),
        get().fetchArtifacts(storeFileId),
        get().fetchStoreFileSignedURL(storeFile?.path),
        get().fetchInitialSchema(revisionID),
      ])
    } catch (error) {
      console.error('Error initializing revision data:', error)
      set({ initialized: false })
    }
  },
})
