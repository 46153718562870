/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum FrontEventType {
  assign = 'assign',
  comment = 'comment',
  inbound = 'inbound',
  tag = 'tag',
}
