/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum OfferConditionType {
  COUNT = 'COUNT',
  AT_LEAST_ONE_COUNT = 'AT_LEAST_ONE_COUNT',
  VALUE = 'VALUE',
  COVERAGE = 'COVERAGE',
}
