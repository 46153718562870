/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum StoreProductPriceSource {
  ACCEPTED_COST_CHANGE = 'ACCEPTED_COST_CHANGE',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
  BULK_UPDATE = 'BULK_UPDATE',
}
