import { CalendarIcon } from '@vori/gourmet-icons'
import { foundations } from '@vori/gourmet-tokens'
import { Popover, positionDefault } from '@reach/popover'
import { useClientId, useOnClickOutside } from '@vori/react-hooks'
import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { OpenStateHookReturn, useControlledState, useOpenState } from '../hooks'

import {
  composeEventHandlers,
  composeRefs,
  createOnChangeEvent,
  getInputAriaProps,
  inputValueAsString,
  toRem,
} from '../utils'

import { Button, ButtonProps } from '../ButtonNext'
import { Card } from '../CardNext'
import { Divider } from '../Divider'
import { Flex } from '../FlexNext'
import { FocusTrap } from '../FocusTrap'
import { Spacer } from '../SpacerNext'
import { VisuallyHidden } from '../VisuallyHidden'
import { VisuallyHiddenInput } from '../VisuallyHiddenInput'

import {
  Calendar,
  CalendarProps,
  HookOptions,
  HookReturn,
  useCalendar,
} from '../CalendarNext'

import {
  getSelectedDaysFromValue,
  getSelectedTimesFromValue,
  stringValueToArrayOfDates,
} from './utils'

function styles(): CSSObject {
  return {
    '&': {
      position: 'relative',

      '[data-gourmet-date-input-trigger]': {
        color: '#70707B',

        '&:focus, &:focus-within, &[data-calendar-visible="true"]': {
          boxShadow: `0 0 0 ${toRem(2)} #FFFFFF, 0 0 0 ${toRem(4)} #6038EF`,
        },

        '&[data-value]': {
          color: '#3F3F46',
        },
      },

      'input[aria-invalid="true"] + [data-gourmet-date-input-trigger]': {
        borderColor: '#F0374A',

        '[data-gourmet-icon]': {
          color: '#F0374A',
        },
      },

      'input:disabled + [data-gourmet-date-input-trigger]': {
        backgroundColor: '#FAFAFA',
        borderColor: '#D1D1D6',
        color: '#70707B',
        opacity: 1,
      },

      '[data-gourmet-calendar-input-inline-container]': {
        left: 0,
        maxWidth: toRem(320),
        minWidth: 'max-content',
        position: 'absolute',
        top: `calc(100% + ${foundations.space['space.050']})`,
        width: '100%',
        zIndex: 1000,
      },
    },
  }
}

const CalendarInputContainer = styled(Flex)(styles)

const StyledButton = styled(Button)({
  flexShrink: 0,
  textAlign: 'left',
  justifyContent: 'flex-start',
  width: 'max-content',
})

type Props = Pick<
  CalendarProps,
  | 'disableTodayDot'
  | 'onClickDate'
  | 'onClickNext'
  | 'onClickPrev'
  | 'renderDate'
  | 'renderFooterControls'
  | 'renderHeaderControls'
  | 'timeInputProps'
  | 'timeInputRangeProps'
  | 'withTimeInput'
  | 'withTimeRangeInput'
> & {
  /**
   * Options to be passed directly to the `useCalendar` hook used internally
   * to generate the calendar.
   */
  calendarOptions?: HookOptions
  /**
   * The ID of the HTML element used to described this input.
   *
   * * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby}
   */
  descriptionID?: string
  /**
   * A label used to describe the intention of the dialog.
   */
  dialogAriaLabel?: string
  /**
   * The ID of the HTML element containing the error message related to this input.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-errormessage}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-invalid}
   */
  errorID?: string
  /**
   * If `true`, the component will take all the available horizontal space.
   */
  fullWidth?: boolean
  /**
   * The ID, if any, of the HTML element used to label the checkbox.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby}
   */
  labelID?: string
  /**
   * Callback function executed when the calendar dialog is closed.
   */
  onCloseCalendar?: () => void
  /**
   * Callback function executed when the calendar dialog is opened.
   */
  onOpenCalendar?: () => void
  /**
   * A render prop used to customized the dialog trigger button.
   */
  renderTrigger?: (
    triggerProps: ButtonProps,
    calendarProps: HookReturn & OpenStateHookReturn,
    triggerLabel: React.ReactNode,
  ) => React.ReactNode
  /**
   * A render prop used to customized the label of the dialog trigger button.
   */
  renderTriggerLabel?: (calendar: HookReturn) => React.ReactNode
  /**
   * Props to be passed directly to the trigger button if no `renderTrigger` prop
   * is passed.
   */
  triggerProps?: ButtonProps
  /**
   * Displays the popup alongside the trigger, instead of a React portal. This
   * is useful when rendering within a dialog displayed as a modal.
   */
  renderPopupInline?: boolean
  /**
   * A boolean flag used to conditionally render confirmation controls for the
   * dialog.
   */
  withConfirmationControls?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

/**
 * A date picker combobox (select-only) that allows users to select dates and
 * date ranges.
 *
 * @example
 * <CalendarInput
 *   renderTriggerLabel={({ selectedDates }) =>
 *     selectedDates.length
 *       ? selectedDates.map(formatDate).join(' + ')
 *       : 'Select Dates :)'
 *   }
 *   onChange={(event) => {
 *     console.log(`Selected date: ${event.target.value}`)
 *   }}
 * />
 *
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/}
 * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/}
 */
const CalendarInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(function CalendarInput(
  {
    calendarOptions,
    defaultValue,
    descriptionID,
    dialogAriaLabel,
    disabled,
    disableTodayDot,
    errorID,
    fullWidth,
    id: controlledID,
    labelID,
    onClickDate,
    onClickNext,
    onClickPrev,
    onCloseCalendar,
    onOpenCalendar: controlledOnOpenCalendar,
    renderDate,
    renderFooterControls: controlledRenderFooterControls,
    renderHeaderControls,
    renderPopupInline,
    renderTrigger,
    renderTriggerLabel: controlledRenderTriggerLabel,
    timeInputProps,
    timeInputRangeProps,
    triggerProps: defaultTriggerProps,
    value: controlledValue,
    withConfirmationControls,
    withTimeInput,
    withTimeRangeInput,
    ...props
  }: React.PropsWithChildren<Props>,
  ref,
): JSX.Element {
  const [defaultID] = useClientId('gourmet-date-input')

  const [inputID] = useControlledState({
    componentName: 'CalendarInput',
    controlledValue: controlledID,
    defaultValue: defaultID,
  })

  const [value, setValue, isControlled] = useControlledState({
    componentName: 'CalendarInput',
    controlledValue,
    defaultValue:
      defaultValue || calendarOptions?.selectedDates?.join(',') || '',
  })

  const originalValueRef = React.useRef(value)
  const temporaryValueRef = React.useRef(value)

  const onOpenCalendar = React.useCallback(() => {
    originalValueRef.current = value
    controlledOnOpenCalendar?.()
  }, [controlledOnOpenCalendar, value])

  const calendarPopover = useOpenState({
    onClose: onCloseCalendar,
    onOpen: onOpenCalendar,
  })

  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)

  const [targetRef, setTargetRef] = React.useState<HTMLButtonElement | null>(
    null,
  )

  const [popoverRef, setPopoverRef] = React.useState<HTMLDivElement | null>(
    null,
  )

  const onDateSelectionChange = React.useCallback<
    (selectedDates: Array<Date>, prevSelectedDates: Array<Date>) => void
  >(
    (selectedDates, prevSelectedDates) => {
      const nextValue = selectedDates.join(',')

      calendarOptions?.onDateSelectionChange?.(selectedDates, prevSelectedDates)

      setValue(nextValue)

      if (!withConfirmationControls && props.onChange != null && inputRef) {
        inputRef.value = nextValue
        props.onChange(createOnChangeEvent(inputRef))
      } else if (withConfirmationControls) {
        temporaryValueRef.current = nextValue
      }
    },
    [calendarOptions, inputRef, props, setValue, withConfirmationControls],
  )

  const calendar = useCalendar({
    ...calendarOptions,
    ...(defaultValue && {
      defaultSelectedDates: getSelectedDaysFromValue(value),
    }),
    ...(defaultValue &&
      (withTimeInput || withTimeRangeInput) && {
        defaultSelectedTimes: getSelectedTimesFromValue(value),
      }),
    ...(isControlled &&
      value && {
        selectedDates: getSelectedDaysFromValue(value),
      }),
    ...(isControlled &&
      (withTimeInput || withTimeRangeInput) &&
      value && {
        selectedTimes: getSelectedTimesFromValue(value),
      }),
    onDateSelectionChange,
  })

  const suppressNativeCalendarAndFocusOnTrigger = React.useCallback(
    (event: React.SyntheticEvent) => {
      if (
        targetRef &&
        !calendarPopover.isOpen &&
        document.activeElement !== targetRef
      ) {
        targetRef.focus()
        event.preventDefault()
      }
    },
    [calendarPopover.isOpen, targetRef],
  )

  const closeAndResetIfNeeded = React.useCallback<() => void>(() => {
    if (withConfirmationControls && originalValueRef.current !== value) {
      calendar.setSelected(
        stringValueToArrayOfDates(originalValueRef.current || ''),
      )
    }

    calendarPopover.close()
    temporaryValueRef.current = ''

    if (targetRef) {
      targetRef.focus()
    }
  }, [calendar, calendarPopover, targetRef, value, withConfirmationControls])

  const renderTriggerLabel = React.useCallback<
    NonNullable<Props['renderTriggerLabel']>
  >(() => {
    return controlledRenderTriggerLabel
      ? controlledRenderTriggerLabel({
          ...calendar,
          selectedDates: stringValueToArrayOfDates(value),
        })
      : value || 'Select Dates'
  }, [calendar, controlledRenderTriggerLabel, value])

  const renderFooterControls = React.useCallback<
    NonNullable<Props['renderFooterControls']>
  >(() => {
    if (!controlledRenderFooterControls && !withConfirmationControls) {
      return null
    }

    return (
      <Flex direction="column" fullWidth>
        {withConfirmationControls && (
          <>
            <Spacer size="space.075" />
            <Flex fullWidth columnOffset="space.125">
              <Divider />
            </Flex>
            <Spacer size="space.075" />
            <Flex
              centerY
              columnOffset="space.050"
              gap="space.100"
              fullWidth
              justifyContent="space-between"
            >
              <Button
                disabled={disabled}
                onClick={closeAndResetIfNeeded}
                size="small"
                fullWidth
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={() => {
                  calendarPopover.close()

                  if (props.onChange != null && inputRef) {
                    inputRef.value = inputValueAsString(
                      temporaryValueRef.current,
                    )

                    props.onChange(createOnChangeEvent(inputRef))
                  }

                  temporaryValueRef.current = ''

                  if (targetRef) {
                    targetRef.focus()
                  }
                }}
                fullWidth
                size="small"
                variant="secondary"
              >
                Apply
              </Button>
            </Flex>
          </>
        )}

        {controlledRenderFooterControls != null && (
          <>
            <Spacer size="space.075" />
            {controlledRenderFooterControls?.({
              deselect: calendar.deselect,
              deselectAll: calendar.deselectAll,
              select: calendar.select,
              setMonth: calendar.setMonth,
              setNextMonth: calendar.setNextMonth,
              setNextYear: calendar.setNextYear,
              setPrevMonth: calendar.setPrevMonth,
              setPrevYear: calendar.setPrevYear,
              setSelected: calendar.setSelected,
              setTime: calendar.setTime,
              setYear: calendar.setYear,
              toggle: calendar.toggle,
            })}
          </>
        )}
      </Flex>
    )
  }, [
    calendar.deselect,
    calendar.deselectAll,
    calendar.select,
    calendar.setMonth,
    calendar.setNextMonth,
    calendar.setNextYear,
    calendar.setPrevMonth,
    calendar.setPrevYear,
    calendar.setSelected,
    calendar.setTime,
    calendar.setYear,
    calendar.toggle,
    calendarPopover,
    closeAndResetIfNeeded,
    controlledRenderFooterControls,
    disabled,
    inputRef,
    props,
    targetRef,
    withConfirmationControls,
  ])

  /**
   * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/#kbd_label_1}
   *
   * @todo Implement focus logic for `ALT + Down Arrow`.
   */
  const handleComboboxKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLButtonElement>
  >(
    (event) => {
      switch (event.key) {
        case 'ArrowDown': {
          calendarPopover.open()
          break
        }

        case 'Escape': {
          closeAndResetIfNeeded()
          break
        }

        default: {
          break
        }
      }
    },
    [calendarPopover, closeAndResetIfNeeded],
  )

  /**
   * @see {@link https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-datepicker/#kbd_label_1}
   */
  const handleDialogKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLDivElement>
  >(
    (event) => {
      switch (event.key) {
        case 'Escape': {
          closeAndResetIfNeeded()
          break
        }

        default: {
          break
        }
      }
    },
    [closeAndResetIfNeeded],
  )

  const triggerProps = React.useMemo<ButtonProps>(
    () => ({
      ...(value && { 'data-value': String(value) }),
      'aria-autocomplete': 'none',
      'aria-controls': `${inputID}-gourmet-calendar-input-dialog`,
      'aria-expanded': calendarPopover.isOpen,
      'aria-haspopup': 'dialog',
      'data-calendar-visible': String(calendarPopover.isOpen),
      disabled,
      leftIcon: <CalendarIcon />,
      role: 'combobox',
      ...defaultTriggerProps,
      ref: setTargetRef,
      tabIndex: 0,
      onClick: composeEventHandlers(defaultTriggerProps?.onClick, (event) => {
        calendarPopover.toggle()
        event.currentTarget.focus()
        event.preventDefault()
      }),
      onKeyDown: composeEventHandlers(
        defaultTriggerProps?.onKeyDown,
        handleComboboxKeyDown,
      ),
    }),
    [
      calendarPopover,
      defaultTriggerProps,
      disabled,
      handleComboboxKeyDown,
      inputID,
      value,
    ],
  )

  const PopoverContent = React.useMemo(
    () => (
      <>
        <Spacer size="space.050" />
        <FocusTrap autoFocus disabled={!calendarPopover.isOpen}>
          {(focusTrapRef, focusTrapProps) => (
            <Card
              ref={composeRefs([setPopoverRef, (el) => focusTrapRef(el)])}
              {...focusTrapProps}
            >
              <Calendar
                {...calendar}
                disabled={disabled}
                disableTodayDot={disableTodayDot}
                onClickDate={(calendarDate) => {
                  calendar.toggle(calendarDate.date)
                  onClickDate?.(calendarDate)
                }}
                onClickNext={(event) => {
                  calendar.setNextMonth()
                  onClickNext?.(event)
                }}
                onClickPrev={(event) => {
                  calendar.setPrevMonth()
                  onClickPrev?.(event)
                }}
                renderDate={renderDate}
                renderFooterControls={renderFooterControls}
                renderHeaderControls={renderHeaderControls}
                timeInputProps={timeInputProps}
                timeInputRangeProps={timeInputRangeProps}
                withTimeInput={withTimeInput}
                withTimeRangeInput={withTimeRangeInput}
              />
            </Card>
          )}
        </FocusTrap>
        <VisuallyHidden aria-live="polite">
          You can use your keyboard to navigate through the dates.
        </VisuallyHidden>
      </>
    ),
    [
      calendar,
      calendarPopover.isOpen,
      disableTodayDot,
      disabled,
      onClickDate,
      onClickNext,
      onClickPrev,
      renderDate,
      renderFooterControls,
      renderHeaderControls,
      timeInputProps,
      timeInputRangeProps,
      withTimeInput,
      withTimeRangeInput,
    ],
  )

  useOnClickOutside(
    [targetRef, popoverRef],
    closeAndResetIfNeeded,
    !calendarPopover.isOpen,
  )

  return (
    <CalendarInputContainer data-gourmet-date-input="" fullWidth={fullWidth}>
      <VisuallyHiddenInput
        {...props}
        {...getInputAriaProps({ descriptionID, errorID, labelID })}
        disabled={disabled}
        type="text"
        focusable
        id={inputID}
        onChange={composeEventHandlers(
          props.onChange,
          suppressNativeCalendarAndFocusOnTrigger,
        )}
        onFocus={composeEventHandlers(
          props.onFocus,
          suppressNativeCalendarAndFocusOnTrigger,
        )}
        ref={composeRefs([ref, setInputRef])}
        value={value}
      />

      {renderTrigger ? (
        renderTrigger(
          triggerProps,
          { ...calendar, ...calendarPopover },
          renderTriggerLabel(calendar),
        )
      ) : (
        <StyledButton {...triggerProps} data-gourmet-date-input-trigger="">
          {renderTriggerLabel(calendar)}
        </StyledButton>
      )}

      {renderPopupInline && calendarPopover.isOpen ? (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
          aria-label={dialogAriaLabel || 'Choose Date'}
          aria-modal="true"
          data-gourmet-calendar-input-inline-container=""
          id={`${inputID}-gourmet-calendar-input-dialog`}
          onKeyDown={handleDialogKeyDown}
          role="dialog"
          style={{ zIndex: 1001 }}
        >
          {PopoverContent}
        </div>
      ) : calendarPopover.isOpen ? (
        <Popover
          aria-label={dialogAriaLabel || 'Choose Date'}
          aria-modal="true"
          id={`${inputID}-gourmet-calendar-input-dialog`}
          onKeyDown={handleDialogKeyDown}
          position={positionDefault}
          role="dialog"
          hidden={!calendarPopover.isOpen}
          style={{ maxWidth: toRem(320), zIndex: 1001 }}
          targetRef={{ current: targetRef }}
        >
          {PopoverContent}
        </Popover>
      ) : null}
    </CalendarInputContainer>
  )
})

CalendarInput.displayName = 'CalendarInput'
CalendarInput.defaultProps = {}

export { CalendarInput, styles as CalendarInputStyles }
export type { Props as CalendarInputProps }
