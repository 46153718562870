import React from 'react'
import { Redirect } from 'react-router'

import { SentryTags } from '@vori/dashboard-constants'
import { AuthErrorSource } from '@vori/dashboard-hooks/useCurrentUser/types'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { routes as authRoutes } from '@vori/dashboard-routes/auth'
import { getFirebaseApp } from '@vori/dashboard-utils'
import { FlexNext as Flex, Loader } from '@vori/gourmet-components'
import { getAuth } from 'firebase/auth'

function LogoutPage(): JSX.Element {
  const app = getFirebaseApp()

  if (!app) {
    return <Redirect to={authRoutes.login.root.path} />
  }

  getAuth(app)
    .signOut()
    .catch((error) => {
      captureException(() => error, {
        sentryTag: `${SentryTags.AUTH_PREFIX}${AuthErrorSource.LOGOUT}`,
      })
    })

  return (
    <Flex center direction="column" flex={1}>
      <Loader size="large" />
    </Flex>
  )
}

export { LogoutPage }
