/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum InvalidDateInImportFileErrorErrorCode {
  invalid_date_in_import_file = 'invalid_date_in_import_file',
}
