import { useIsMounted } from '@vori/react-hooks'
import styled from 'styled-components'

import React, {
  forwardRef,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react'

import {
  positionDefault,
  positionMatchWidth,
  positionRight,
} from '@reach/popover'

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button'

import { CalendarIcon } from '@vori/gourmet-icons'
import { CardProps } from '../Card'
import Calendar, { CalendarDate, CalendarProps } from '../Calendar'
import Button, { ButtonProps } from '../Button'
import Flex from '../Flex'
import HiddenInput from '../HiddenInput'
import Spacer from '../Spacer'
import Text from '../Text'

import { colors, Size, sizing, spacing, typography } from '../tokens'

import useCalendar, {
  CalendarOptions,
  CalendarHookReturn,
} from '../Calendar/useCalendar'

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})

type DatePosition = 'default' | 'right' | 'matchWidth'
type DatePickerSize = Extract<Size, 'small' | 'base'>

type DatePickerProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
> & {
  calendarOptions?: CalendarOptions
  calendarProps?: CalendarProps
  disableDateToggle?: boolean
  fullWidth?: boolean
  label?: string
  noLabel?: boolean
  onClear?: () => void
  onDateChange?: (date: Date | null) => void
  placeholder?: string
  popupProps?: CardProps
  position?: DatePosition
  renderCalendarFooter?: (
    calendarHookProps: CalendarHookReturn & { close: () => void },
  ) => React.ReactNode
  size?: DatePickerSize
  triggerProps?: Omit<ButtonProps, 'as' | 'size'>
}

type DatePickerContainerProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  fullWidth?: boolean
}

const defaultProps: Partial<DatePickerProps> = {
  calendarOptions: {},
  calendarProps: {
    dates: [],
  },
  className: '',
  disableDateToggle: false,
  fullWidth: false,
  label: 'Date',
  noLabel: false,
  placeholder: 'Choose Date',
  popupProps: {
    size: Calendar.defaultProps?.size,
  },
  position: 'default',
  size: 'base',
}

const StyledMenuPopover = styled(MenuPopover)<{
  $size: CalendarProps['size']
}>`
  max-width: ${({ $size = defaultProps.size }) =>
    $size != null ? sizing.card[$size as Size] : 'auto'};
  width: ${({ $size }) => ($size != null ? '100%' : 'auto')};
  z-index: 1000;
`

const DatePickerTrigger = styled(Button)<
  ButtonProps & { $isExpanded?: boolean }
>(({ $isExpanded, disableFocusRing }) => ({
  borderColor: colors.input.borderColor.default,
  justifyContent: 'space-between',
  padding: spacing.input.base,

  ':active, :focus, :hover': {
    borderColor: colors.input.borderColor.default,
  },

  '&, &:hover, &:active': {
    ...($isExpanded && {
      backgroundColor: colors.button.hover.backgroundColor.secondary,
      borderColor: colors.input.borderColor.default,
      color: colors.button.color.secondary,
    }),
  },

  ':focus, :active, :focus-within': {
    borderColor: colors.input.focus.borderColor.default,
  },

  ...($isExpanded && {
    boxShadow: !disableFocusRing
      ? `0 0 0 ${sizing.focusRing} ${colors.button.focusRing.secondary}`
      : 'none',
  }),

  ':focus, :focus-within': {
    boxShadow: !disableFocusRing
      ? `0 0 0 ${sizing.focusRing} ${colors.input.focusRing}`
      : 'none',
  },
}))

const StyledDatePickerContainer = styled(
  forwardRef<HTMLLabelElement, DatePickerContainerProps>(
    function DatePickerContainer(
      { fullWidth, ...props }: DatePickerContainerProps,
      ref,
    ) {
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      return <label {...props} ref={ref} />
    },
  ),
)<DatePickerContainerProps>`
  display: inline-block;
  position: relative;
  ${({ fullWidth }): string => (fullWidth ? 'width: 100%;' : '')}
  ${({ fullWidth }): string =>
    fullWidth ? `${DatePickerTrigger} { width: 100%; }` : ''}
`

const ValueText = styled(Text)({
  fontSize: typography.button.base.fontSize,
  fontWeight: typography.button.base.fontWeight,
  lineHeight: typography.button.base.lineHeight,
})

/**
 * @deprecated Use `<DateInput>` instead.
 */
const StyledDatePicker = styled(
  forwardRef<HTMLLabelElement, DatePickerProps>(function DatePicker(
    {
      calendarOptions,
      calendarProps,
      className,
      disableDateToggle,
      fullWidth,
      label,
      noLabel,
      onClear,
      onDateChange,
      placeholder,
      popupProps,
      position,
      renderCalendarFooter,
      size,
      triggerProps,
      ...props
    }: DatePickerProps,
    ref,
  ) {
    const isMounted = useIsMounted()
    const menuButtonRef = useRef<HTMLButtonElement | null>(null)

    const calendarPropsFromHook = useCalendar({
      ...calendarOptions,
      maxSelected: 1,
    })

    const selectedDate = useMemo<Date | null>(
      () => calendarPropsFromHook.selectedDates[0] || null,
      [calendarPropsFromHook.selectedDates],
    )

    const previousSelectedDate = useRef<Date | null>(selectedDate)

    useEffect(() => {
      const prevDate = previousSelectedDate.current

      if (onClear != null && prevDate !== selectedDate && !selectedDate) {
        previousSelectedDate.current = selectedDate
        onClear()
      }

      if (onDateChange != null && prevDate !== selectedDate) {
        previousSelectedDate.current = selectedDate
        onDateChange(selectedDate)
      }
    }, [onClear, onDateChange, selectedDate])

    const triggerMenuButtonClick = useCallback(() => {
      if (isMounted && menuButtonRef.current !== null) {
        menuButtonRef.current.dispatchEvent(
          new Event('mousedown', {
            bubbles: true,
            cancelable: true,
            composed: true,
          }),
        )
      }
    }, [isMounted])

    return (
      <StyledDatePickerContainer
        className={className}
        fullWidth={fullWidth as boolean}
        ref={ref}
      >
        <Menu>
          {({ isExpanded }) => (
            <>
              <MenuButton
                $isExpanded={isExpanded}
                disabled={props.disabled}
                leftIcon={<CalendarIcon />}
                {...triggerProps}
                as={DatePickerTrigger}
                ref={menuButtonRef}
                size={size}
              >
                <Flex column>
                  {!noLabel && (
                    <Text size="label" variant="primary">
                      {label}
                    </Text>
                  )}
                  <ValueText size={size === 'small' ? 'caption' : 'display'}>
                    {calendarPropsFromHook.selectedDates.length
                      ? dateFormatter.format(
                          calendarPropsFromHook.selectedDates[0],
                        )
                      : placeholder}
                  </ValueText>
                </Flex>
              </MenuButton>

              <HiddenInput
                {...props}
                value={
                  calendarPropsFromHook.selectedDates[0]?.toISOString() || ''
                }
              />

              {isExpanded && (
                <StyledMenuPopover
                  $size={popupProps?.size}
                  position={
                    position === 'default'
                      ? positionDefault
                      : position === 'right'
                        ? positionRight
                        : position === 'matchWidth'
                          ? positionMatchWidth
                          : positionDefault
                  }
                >
                  <Spacer size="small" />
                  <MenuItems>
                    <Calendar
                      {...calendarProps}
                      dates={calendarPropsFromHook.dates}
                      fullWidth
                      month={calendarPropsFromHook.formattedMonth}
                      onClickNext={calendarPropsFromHook.getNextMonth}
                      onClickPrev={calendarPropsFromHook.getPrevMonth}
                      year={calendarPropsFromHook.formattedYear}
                      renderDate={({
                        date,
                        isDisabled,
                        isFromCurrentMonth,
                        isSelected,
                        isToday,
                        selectDate,
                        toggleDate,
                      }): React.ReactNode => {
                        const currentDate = date.getDate()

                        return (
                          <MenuItem
                            as={CalendarDate}
                            disabled={isDisabled}
                            isDisabled={isDisabled}
                            isFromCurrentMonth={isFromCurrentMonth}
                            isSelected={isSelected}
                            isToday={isToday}
                            key={`${label}-day-${currentDate}`}
                            onSelect={
                              disableDateToggle ? selectDate : toggleDate
                            }
                          >
                            {currentDate}
                          </MenuItem>
                        )
                      }}
                    >
                      {renderCalendarFooter != null
                        ? renderCalendarFooter({
                            ...calendarPropsFromHook,
                            close: triggerMenuButtonClick,
                          })
                        : null}
                    </Calendar>
                  </MenuItems>
                  <Spacer size="small" />
                </StyledMenuPopover>
              )}
            </>
          )}
        </Menu>
      </StyledDatePickerContainer>
    )
  }),
)``

StyledDatePicker.displayName = 'DatePicker'
StyledDatePicker.defaultProps = defaultProps

export type { DatePickerProps }
export { defaultProps as DatePickerDefaultProps }
export default StyledDatePicker
