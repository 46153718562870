/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum GiftCardTransactionType {
  order_payment = 'order_payment',
  funding_from_payment = 'funding_from_payment',
  manual_adjustment = 'manual_adjustment',
  book_transfer = 'book_transfer',
}
