import React from 'react'
import { Menu } from '@reach/menu-button'

interface Props {
  children: React.ReactNode
}

/**
 * A generic popup component based on Reach UI's `<Menu>` component.
 *
 * @example
 * <Popup>
 *   <PopupTrigger>Trigger</PopupTrigger>
 *   <PopupContent column>
 *     <Text>Hello</Text>
 *     <Spacer />
 *     <PopupClose>
 *       <Button>Close</Button>
 *     </PopupClose>
 *   </PopupContent>
 * </Popup>
 *
 * @see {@link https://reach.tech/menu-button}
 */
function Popup({ children }: Props): JSX.Element {
  return <Menu>{children}</Menu>
}

Popup.displayName = 'Popup'
Popup.defaultProps = {}

export { Popup }
export type { Props as PopupProps }
