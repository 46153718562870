/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum SlopeOrderStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  submitted = 'submitted',
  opening = 'opening',
  open = 'open',
  finalizing = 'finalizing',
  finalized = 'finalized',
  unpaid = 'unpaid',
  partiallyPaid = 'partiallyPaid',
  complete = 'complete',
  defaulted = 'defaulted',
  canceled = 'canceled',
  refunded = 'refunded',
}
