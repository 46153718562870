import { defaultAggridState } from '@vori/dashboard-components/store-files/invoices/utils'
import { previewProductsTransaction } from '@vori/dashboard-rest-next/aplrevision-row/aplrevision-row'
import { listBackOfficeProducts } from '@vori/dashboard-rest-next/products/products'
import {
  APLRevisionRowDTO,
  BackOfficeStoreProductView,
} from '@vori/dashboard-rest-next/schemas'
import { customAxiosInstance } from '@vori/dashboard-rest-utils/CustomAxiosInstance'
import { StoreProduct } from '@vori/dashboard-types'
import { StateCreator } from 'zustand'
import { INVOICE_DETAIL_ENDPOINT } from '../pages/retail/pages/invoice-next/constants'
import {
  CurrentRevisionRow,
  InvoiceDetail,
} from '../pages/retail/pages/invoice-next/types'
import {
  filterCostChanges,
  filterItemsToPrint,
  getAllLineItemValues,
  getCurrentRevisionRows,
} from '../pages/retail/pages/invoice-next/utils/utils'

export type InvoiceDetailsSlice = {
  initialized: boolean
  revisionID: string
  invoiceDetails: InvoiceDetail | null
  revisionData: APLRevisionRowDTO[] | null
  productData: BackOfficeStoreProductView[] | null
  lineItems: CurrentRevisionRow[]
  costChanges: CurrentRevisionRow[]
  itemsToPrint: CurrentRevisionRow[]
  newItems: CurrentRevisionRow[]
  loadingInvoice: boolean
  loadingRevision: boolean
  loadingProducts: boolean
  errorInvoice: Error | string | null
  errorRevision: Error | string | null
  errorProducts: Error | string | null
  handleProductQueryMutation: () => Promise<
    { data: StoreProduct[] } | undefined
  >
  handleLineItemQueryMutation: (
    mutation: Promise<APLRevisionRowDTO | CurrentRevisionRow>,
    newData: Partial<APLRevisionRowDTO | CurrentRevisionRow>,
    revalidate?: boolean,
  ) => Promise<{ data: APLRevisionRowDTO | CurrentRevisionRow[] } | undefined>
  initialize: (invoiceID: string, forceRefresh?: boolean) => Promise<void>
  fetchProducts: (ids: string[]) => Promise<void>
  fetchInvoiceHeader: (invoiceID: string) => Promise<InvoiceDetail | null>
  fetchRevisionData: (
    revisionID?: string,
  ) => Promise<APLRevisionRowDTO[] | null>
  setRevisionID: (revisionID: string) => void
  reset: () => void
}

export const createInvoiceDetailsSlice: StateCreator<InvoiceDetailsSlice> = (
  set,
  get,
) => ({
  initialized: false,
  loadingInvoice: true,
  loadingRevision: true,
  loadingProducts: true,
  errorInvoice: null,
  errorRevision: null,
  errorProducts: null,
  invoiceDetails: null,
  revisionData: null,
  productData: null,
  revisionID: '',
  lineItems: [],
  costChanges: [],
  itemsToPrint: [],
  newItems: [],
  handleProductQueryMutation: async () => {
    return undefined
  },
  handleLineItemQueryMutation: async (mutation, newData, revalidate) => {
    const orignalLineItems = get().lineItems
    const { id } = newData
    const updatedLineItems = orignalLineItems.map((lineItem) =>
      lineItem.id === id
        ? ({ ...lineItem, ...newData } as CurrentRevisionRow)
        : lineItem,
    )

    const { costChanges, itemsToPrint, newItems, lineItems } =
      getAllLineItemValues(updatedLineItems)

    set({ lineItems, costChanges, newItems, itemsToPrint })

    try {
      await mutation
    } catch (error) {
      console.error('Error updating line item:', error)

      const { costChanges, itemsToPrint, newItems, lineItems } =
        getAllLineItemValues(orignalLineItems)

      set({ lineItems, costChanges, newItems, itemsToPrint })

      return { data: lineItems }
    }

    if (revalidate) {
      await get().fetchRevisionData(get().revisionID)
    }
    return undefined
  },

  reset: () => {
    set({
      loadingInvoice: false,
      loadingRevision: false,
      loadingProducts: false,
      initialized: false,
      invoiceDetails: null,
      revisionData: null,
      productData: null,
      lineItems: [],
      costChanges: [],
      itemsToPrint: [],
      newItems: [],
      errorInvoice: null,
      errorRevision: null,
      errorProducts: null,
    })
  },
  fetchProducts: async (ids: string[] = []) => {
    try {
      set({ loadingProducts: true, errorProducts: null })

      const resolvedIds =
        ids.length > 0
          ? ids
          : get()
              .revisionData?.map((row) => row.current?.matchID as string)
              .filter(Boolean) || []

      if (resolvedIds.length === 0) {
        return
      }
      const { data: productData = [] } = await listBackOfficeProducts({
        state: JSON.stringify({
          ...defaultAggridState,
          filterModel: {
            id: { values: resolvedIds, filterType: 'set' },
          },
        }),
      })
      set({
        productData,
      })
    } catch (error) {
      console.error('Error initializing product data', error)
      set({ errorProducts: error as Error })
    } finally {
      set({ loadingProducts: false })
    }
  },

  fetchInvoiceHeader: async (invoiceID: string) => {
    try {
      set({ loadingInvoice: true, errorInvoice: null })
      // there is no openApi generated endpoint / orval for this
      // using customAxiosInstance for now
      const invoiceDetails = await customAxiosInstance<InvoiceDetail>({
        url: `${INVOICE_DETAIL_ENDPOINT}?invoiceID=${invoiceID}`,
        method: 'GET',
      })

      const revisionID = invoiceDetails?.revisionID || ''

      const invoice = invoiceDetails || null
      set({
        revisionID,
        invoiceDetails: invoice,
      })
      return invoice
    } catch (error) {
      console.error('Error initializing invoice data', error)
      set({ initialized: false, errorInvoice: error as Error })
      return null
    } finally {
      set({ loadingInvoice: false })
    }
  },

  fetchRevisionData: async (revisionID?: string) => {
    try {
      set({ loadingRevision: true, errorRevision: null })
      const resolvedRevisionID = revisionID || get().revisionID

      if (!resolvedRevisionID) {
        throw new Error('No revision ID provided')
      }

      const { data: revisionData } = await previewProductsTransaction(
        resolvedRevisionID,
        {
          state: JSON.stringify(defaultAggridState),
        },
      )

      const lineItems: CurrentRevisionRow[] = getCurrentRevisionRows(
        revisionData || [],
      )

      const costChanges = filterCostChanges(lineItems)

      const newItems =
        lineItems?.filter((e) => e.isUnauthorized || e.state?.isUnauthorized) ||
        []

      const itemsToPrint = filterItemsToPrint(lineItems)

      set({
        lineItems,
        costChanges,
        itemsToPrint,
        newItems,
        revisionData: revisionData || [],
      })
      return revisionData
    } catch (error) {
      console.error('Error initializing invoice data:', error)
      set({ initialized: false, errorRevision: error as Error })
      return null
    } finally {
      set({ loadingRevision: false })
    }
  },

  setRevisionID: (revisionID: string) => {
    get().reset()

    set({ revisionID, initialized: false })
  },
  initialize: async (invoiceID: string, forceRefresh = false) => {
    const { revisionID, initialized } = get()

    // Only proceed if not initialized or if a forceRefresh is requested
    if (initialized && !forceRefresh) {
      return
    }

    try {
      let invoiceDetails: InvoiceDetail | null = null
      let revisionData: APLRevisionRowDTO[] | null = null

      if (revisionID) {
        // If revisionID is known, fetch both in parallel
        const [fetchedInvoice, fetchedRevision] = await Promise.all([
          get().fetchInvoiceHeader(invoiceID),
          get().fetchRevisionData(revisionID),
        ])
        invoiceDetails = fetchedInvoice
        revisionData = fetchedRevision
      } else {
        // If revisionID not set, fetch invoice header first
        invoiceDetails = await get().fetchInvoiceHeader(invoiceID)
        if (invoiceDetails?.revisionID) {
          revisionData = await get().fetchRevisionData(
            invoiceDetails.revisionID,
          )
        }
      }
      set({ initialized: true })

      // Fetch products after revision data is available
      const productIds =
        revisionData
          ?.map((row) => row.current?.matchID as string)
          .filter(Boolean) || []
      if (productIds.length > 0) {
        get().fetchProducts(productIds)
      }
    } catch (error) {
      console.error('Error initializing invoice data:', error)
      set({ initialized: false })
    }
  },
})
