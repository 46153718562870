/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum InvalidCustomQuickActionsErrorErrorCode {
  invalid_custom_quick_actions = 'invalid_custom_quick_actions',
}
