/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum LoyaltyNotificationType {
  adhoc_campaign = 'adhoc_campaign',
  anniversary = 'anniversary',
  basket_lift = 'basket_lift',
  gift_card_balance_adjustment = 'gift_card_balance_adjustment',
  gift_card_created = 'gift_card_created',
  gift_card_deactivated = 'gift_card_deactivated',
  gift_card_top_up = 'gift_card_top_up',
  happy_birthday = 'happy_birthday',
  signup = 'signup',
  store_visit_nudge = 'store_visit_nudge',
  reward_achieved = 'reward_achieved',
  reward_distance = 'reward_distance',
  reward_redeemed = 'reward_redeemed',
}
