/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum CardBrand {
  american_express = 'american_express',
  bill_me_later = 'bill_me_later',
  debit = 'debit',
  diners_club = 'diners_club',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  other = 'other',
  telecheck = 'telecheck',
  undetermined = 'undetermined',
  revolution_money = 'revolution_money',
  visa = 'visa',
  voyager = 'voyager',
  wright_express = 'wright_express',
  atm = 'atm',
  ebt = 'ebt',
}
