import { AppGetCurrentUserQuery } from '@vori/gql-dashboard'

export type UserDataFromGQL = NonNullable<AppGetCurrentUserQuery['me']>['user']

export type CurrentUserState = {
  isAuthenticating: boolean
  isLoggedIn: boolean
}

export type CurrentUserData = UserDataFromGQL & {
  companyID: string
  companyName: string
  isBuyer: boolean
  isDepartmentManager: boolean
  isRetailUser: boolean
  isSalesRep: boolean
  isStoreAdmin: boolean
  isStoreAP: boolean
  isVendorAdmin: boolean
  isVendorUser: boolean
  isVoriAdmin: boolean
}

export type CurrentUserMetadata = {
  selectedStoreID: string | null
  selectedStoreName: string | null
  timeZone: string
}

export type CurrentUser = {
  authToken: string | null
  data: CurrentUserData
  metadata: CurrentUserMetadata
  state: CurrentUserState
}

export type CurrentUserReducerState = {
  authenticationError: Error | null
  user: CurrentUser
}

export enum AuthErrorSource {
  AUTH_STATE_CHANGED = 'auth_state_changed',
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export type CurrentUserReducerAction =
  | { type: 'userV2/authenticationStarted' }
  | {
      type: 'userV2/authenticated'
      payload: {
        authToken: string
        data: CurrentUserData | UserDataFromGQL
      }
    }
  | { type: 'userV2/unauthenticated' }
  | { type: 'userV2/authenticationFailed'; payload: { error: Error } }
  | {
      type: 'userV2/storeSelected'
      payload: {
        selectedStoreID: string
        selectedStoreName: string
        selectedStoreTimeZone: string
      }
    }
  | {
      type: 'userV2/featureFlagsUpdated'
      payload: {
        featureFlags: Array<string>
      }
    }

export type CurrentUserStateContextValue = CurrentUserReducerState

export type CurrentUserDispatchContextValue =
  React.Dispatch<CurrentUserReducerAction>
