import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'

export type CourierToken = {
  exp: number
  iat: number
  jti: string
  scope: string
  tenant_id: string
  tenant_scope: string
}

export function decodeToken(token: string): CourierToken {
  return jwtDecode<CourierToken>(token)
}

export function isTokenExpired(token: string | null): boolean {
  const baseDate = dayjs(new Date())
  const timestamp = baseDate.unix()
  if (!token || token === '') {
    return true
  }

  const tokenDecode = decodeToken(token)
  const isExpired = tokenDecode.exp <= timestamp

  return isExpired
}
