import { CustomFilterProps } from 'ag-grid-react'
import React from 'react'

/**
 * This custom filter component essentially works as agGrid's set filter, but
 * it allows us to send an array of values on server-side grids without having
 * to supply all initial values through `filterParams`.
 *
 * Use this filter instead of `DataGridArrayFilter` if you are filtering a column
 * that is displaying a single value, e.g. an ID, username, etc.
 *
 * @see {@link https://www.ag-grid.com/react-data-grid/component-filter/#simple-filter}
 * @see {@link https://www.ag-grid.com/react-data-grid/server-side-model-filtering/#set-filter-values}
 */

const DataGridVSetFilter = React.forwardRef((props: CustomFilterProps, ref) => {
  const [values, setValues] = React.useState<Array<string> | null>(
    props.column.getColDef().filterParams?.values || null,
  )

  React.useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return true
      },

      getModel() {
        return { filterType: 'vset', values }
      },

      setModel(value?: { values: Array<string> } | null) {
        if (value === undefined) {
          return
        }

        setValues(value?.values || null)
      },

      onFilterChanged() {
        return void 0
      },
    }
  })

  React.useEffect(() => {
    props.api.onFilterChanged()
    props.api.ensureIndexVisible(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Array.isArray(values) ? values.toString() : values])

  return null
})

DataGridVSetFilter.displayName = 'DataGridVSetFilter'
export { DataGridVSetFilter }
