import { Location } from 'history'
import React from 'react'

import { AppSidebarLink, AppSidebarLinks } from '@vori/gourmet-components'

import {
  BankIcon,
  BarChartSquare01Icon,
  BookOpen01Icon,
  Building02Icon,
  Dataflow04Icon,
  Globe03Icon,
  IntersectCircleIcon,
  Mail01Icon,
  Tool02Icon,
  Truck01Icon,
  Upload01Icon,
  UploadCloud01Icon,
  Users01Icon,
} from '@vori/gourmet-icons'

import { routes } from '@vori/dashboard-routes/dev'

function openOrderGuideUploadFlow(location: Location): boolean {
  return new URLSearchParams(location?.search)?.get('uploader-type') === 'true'
}

/**
 * @todo Moving forwards, these links will be enabled based on the current
 * user's permissions. For the time being, only "store admins" have access
 * to navigation within the retail dashboard.
 */
const links: Record<string, JSX.Element> = {
  metrics: (
    <AppSidebarLink
      href={routes.metrics.root.path}
      icon={<BarChartSquare01Icon />}
      key="sidebar-navigation-metrics"
      label="Metrics"
    />
  ),
  users: (
    <AppSidebarLink
      href={routes.users.root.path}
      icon={<Users01Icon />}
      key="sidebar-navigation-users"
      label="Users"
    />
  ),
  stores: (
    <AppSidebarLink
      href={routes.stores.root.path}
      icon={<Building02Icon />}
      key="sidebar-navigation-stores"
      label="Stores"
    />
  ),
  vendors: (
    <AppSidebarLink
      href={routes.vendors.root.path}
      icon={<Truck01Icon />}
      key="sidebar-navigation-vendors"
      label="Vendors"
    />
  ),
  vendorsNew: (
    <AppSidebarLink
      href={routes.adminVendors.root.path}
      icon={<Truck01Icon />}
      key="sidebar-navigation-vendors-new"
      label="Vendors (New)"
    />
  ),
  products: (
    <AppSidebarLink
      href={routes.products.root.path}
      icon={<BookOpen01Icon />}
      key="sidebar-navigation-products"
      label="Products"
    />
  ),
  commandCenter: (
    <AppSidebarLink
      href={routes.commandCenter.products.path}
      icon={<Globe03Icon />}
      key="sidebar-navigation-command-center"
      label="Command Center"
    />
  ),
  storeVendorProducts: (
    <AppSidebarLink
      href={routes.storeVendorCatalog.root.path}
      icon={<IntersectCircleIcon />}
      key="sidebar-navigation-command-center"
      label="Store Vendor Catalog"
    />
  ),
  catalogUploader: (
    <AppSidebarLink
      href={routes.products.upload.path}
      icon={<Upload01Icon />}
      key="sidebar-navigation-catalog-uploader"
      navLinkProps={{
        isActive: (match, location) =>
          !match || openOrderGuideUploadFlow(location) ? false : true,
      }}
      label="Catalog Uploader"
    />
  ),
  orderGuideUpload: (
    <AppSidebarLink
      href={routes.products.uploadOrderGuide.path}
      navLinkProps={{
        isActive: (_, location) =>
          !openOrderGuideUploadFlow(location) ? false : true,
      }}
      icon={<UploadCloud01Icon />}
      key="sidebar-navigation-order-guide-upload"
      label="Order Guide Upload"
    />
  ),
  bannerUpload: (
    <AppSidebarLink
      href={routes.banners.root.path}
      icon={<BankIcon />}
      key="sidebar-navigation-banners"
      label="Banners"
    />
  ),
  vendorInvitations: (
    <AppSidebarLink
      href={routes.invitations.adminVendorInvitations.path}
      icon={<Mail01Icon />}
      key="sidebar-navigation-vendor-invites"
      label="Vendor Invitations"
    />
  ),
  storeInvitations: (
    <AppSidebarLink
      href={routes.invitations.adminStoreInvitations.path}
      icon={<Mail01Icon />}
      key="sidebar-navigation-store-invites"
      label="Store Invitations"
    />
  ),

  retool: (
    <AppSidebarLink
      href={routes.retool.root.path}
      icon={<Tool02Icon />}
      key="sidebar-navigation-retool"
      label="Retool"
    />
  ),

  storeFiles: (
    <AppSidebarLink
      href={routes.storeFiles.root.path}
      icon={<Dataflow04Icon />}
      key="store-files"
      label="Store Files"
    />
  ),
  revisions: (
    <AppSidebarLink
      href={routes.revisions.root.path}
      icon={<UploadCloud01Icon />}
      key="revisions"
      label="Revisions"
    />
  ),
  eslUpload: (
    <AppSidebarLink
      href={routes.eslUpload.root.path}
      icon={<UploadCloud01Icon />}
      key="esl-upload"
      label="ESL Upload"
    />
  ),
}

function DevNavigation(): JSX.Element | null {
  return <AppSidebarLinks>{Object.values(links)}</AppSidebarLinks>
}

export { DevNavigation }
