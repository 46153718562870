import * as Sentry from '@sentry/react'
import { SentryTags } from '@vori/dashboard-constants'

import { getFullStorySessionURL } from '@vori/dashboard-integrations/FullStory/getFullStorySessionURL'

import {
  ENV_PROD,
  ENV_SENTRY_ENABLE_LOCAL_REPORTING,
} from '@vori/dashboard-env'

type CaptureFunctionOptions = {
  level?: Sentry.SeverityLevel
  sentryTag: SentryTags | string
}

export function captureException(
  callback: (scope: Sentry.Scope) => Error,
  options?: CaptureFunctionOptions,
) {
  if (!ENV_PROD && !ENV_SENTRY_ENABLE_LOCAL_REPORTING) {
    return
  }

  const { level = 'error', sentryTag = SentryTags.GENERIC_ERROR_BOUNDARY } =
    options || {}

  const sessionURL = getFullStorySessionURL()

  try {
    Sentry.withScope((scope) => {
      if (sessionURL) {
        scope.setContext('fullstorySession', { url: sessionURL })
      }

      scope.setLevel(level)
      scope.setTag(SentryTags.CAPTURE_EXCEPTION, sentryTag)
      Sentry.captureException(callback(scope))
    })
  } catch (err) {
    console.error('Failed to capture exception with Sentry', err)
  }
}

export function captureMessage(
  callback: (scope: Sentry.Scope) => string,
  options?: CaptureFunctionOptions,
) {
  if (!ENV_PROD && !ENV_SENTRY_ENABLE_LOCAL_REPORTING) {
    return
  }

  const { level = 'info', sentryTag = SentryTags.GENERIC_ERROR_BOUNDARY } =
    options || {}

  const sessionURL = getFullStorySessionURL()

  try {
    Sentry.withScope((scope) => {
      if (sessionURL) {
        scope.setContext('fullstorySession', { url: sessionURL })
      }

      scope.setLevel(level)
      scope.setTag(SentryTags.CAPTURE_MESSAGE, sentryTag)
      Sentry.captureMessage(callback(scope))
    })
  } catch (err) {
    console.error('Failed to capture event with Sentry', err)
  }
}
