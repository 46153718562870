/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum LaneOrderIdPrefixAlreadyAssignedErrorErrorCode {
  lane_order_id_prefix_already_assigned = 'lane_order_id_prefix_already_assigned',
}
