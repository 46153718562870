/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Arguments, Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  AdminListRevisionParams,
  CreateRevisionCandidateDto,
  CreateRevisionDto,
  CreateRevisionScoringStrategyDto,
  ListRevisionCandidateEvaluationByRevisionParams,
  ListRevisionCandidateParams,
  ListRevisionParams,
  RevisionCandidateDto,
  RevisionCandidateEvaluationDto,
  RevisionDto,
  RevisionListDto,
  RevisionScoringStrategyDto,
  UpdateRevisionCandidateDto,
  UpdateRevisionDto,
  UpdateRevisionScoringStrategyDto,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const adminCreateRevision = (
  createRevisionDto: CreateRevisionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionDto>(
    {
      url: `/v1/admin/revisions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createRevisionDto,
    },
    options,
  )
}

export const getAdminCreateRevisionMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateRevisionDto },
  ): Promise<RevisionDto> => {
    return adminCreateRevision(arg, options)
  }
}
export const getAdminCreateRevisionMutationKey = () =>
  [`/v1/admin/revisions`] as const

export type AdminCreateRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminCreateRevision>>
>
export type AdminCreateRevisionMutationError = ErrorType<void>

export const useAdminCreateRevision = <TError = ErrorType<void>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof adminCreateRevision>>,
    TError,
    Key,
    CreateRevisionDto,
    Awaited<ReturnType<typeof adminCreateRevision>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getAdminCreateRevisionMutationKey()
  const swrFn = getAdminCreateRevisionMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const adminListRevision = (
  params?: AdminListRevisionParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionListDto>(
    { url: `/v1/admin/revisions`, method: 'GET', params },
    options,
  )
}

export const getAdminListRevisionKey = (params?: AdminListRevisionParams) =>
  [`/v1/admin/revisions`, ...(params ? [params] : [])] as const

export type AdminListRevisionQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminListRevision>>
>
export type AdminListRevisionQueryError = ErrorType<unknown>

export const useAdminListRevision = <TError = ErrorType<unknown>>(
  params?: AdminListRevisionParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof adminListRevision>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getAdminListRevisionKey(params) : null))
  const swrFn = () => adminListRevision(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const adminGetRevision = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionDto>(
    { url: `/v1/admin/revisions/${id}`, method: 'GET' },
    options,
  )
}

export const getAdminGetRevisionKey = (id: string) =>
  [`/v1/admin/revisions/${id}`] as const

export type AdminGetRevisionQueryResult = NonNullable<
  Awaited<ReturnType<typeof adminGetRevision>>
>
export type AdminGetRevisionQueryError = ErrorType<void>

export const useAdminGetRevision = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof adminGetRevision>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getAdminGetRevisionKey(id) : null))
  const swrFn = () => adminGetRevision(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const adminUpdateRevision = (
  id: string,
  updateRevisionDto: UpdateRevisionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionDto>(
    {
      url: `/v1/admin/revisions/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateRevisionDto,
    },
    options,
  )
}

export const getAdminUpdateRevisionMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateRevisionDto },
  ): Promise<RevisionDto> => {
    return adminUpdateRevision(id, arg, options)
  }
}
export const getAdminUpdateRevisionMutationKey = (id: string) =>
  [`/v1/admin/revisions/${id}`] as const

export type AdminUpdateRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminUpdateRevision>>
>
export type AdminUpdateRevisionMutationError = ErrorType<void>

export const useAdminUpdateRevision = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof adminUpdateRevision>>,
      TError,
      Key,
      UpdateRevisionDto,
      Awaited<ReturnType<typeof adminUpdateRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getAdminUpdateRevisionMutationKey(id)
  const swrFn = getAdminUpdateRevisionMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const adminDeleteRevision = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/admin/revisions/${id}`, method: 'DELETE' },
    options,
  )
}

export const getAdminDeleteRevisionMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return adminDeleteRevision(id, options)
  }
}
export const getAdminDeleteRevisionMutationKey = (id: string) =>
  [`/v1/admin/revisions/${id}`] as const

export type AdminDeleteRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof adminDeleteRevision>>
>
export type AdminDeleteRevisionMutationError = ErrorType<void>

export const useAdminDeleteRevision = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof adminDeleteRevision>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof adminDeleteRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getAdminDeleteRevisionMutationKey(id)
  const swrFn = getAdminDeleteRevisionMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listRevisionCandidateEvaluationByRevision = (
  params: ListRevisionCandidateEvaluationByRevisionParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCandidateEvaluationDto[]>(
    { url: `/v1/revision-candidate-evaluations`, method: 'GET', params },
    options,
  )
}

export const getListRevisionCandidateEvaluationByRevisionKey = (
  params: ListRevisionCandidateEvaluationByRevisionParams,
) =>
  [`/v1/revision-candidate-evaluations`, ...(params ? [params] : [])] as const

export type ListRevisionCandidateEvaluationByRevisionQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRevisionCandidateEvaluationByRevision>>
>
export type ListRevisionCandidateEvaluationByRevisionQueryError =
  ErrorType<unknown>

export const useListRevisionCandidateEvaluationByRevision = <
  TError = ErrorType<unknown>,
>(
  params: ListRevisionCandidateEvaluationByRevisionParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listRevisionCandidateEvaluationByRevision>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListRevisionCandidateEvaluationByRevisionKey(params)
        : null)
  const swrFn = () =>
    listRevisionCandidateEvaluationByRevision(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createRevisionCandidate = (
  createRevisionCandidateDto: CreateRevisionCandidateDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCandidateDto>(
    {
      url: `/v1/revision-candidates`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createRevisionCandidateDto,
    },
    options,
  )
}

export const getCreateRevisionCandidateMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateRevisionCandidateDto },
  ): Promise<RevisionCandidateDto> => {
    return createRevisionCandidate(arg, options)
  }
}
export const getCreateRevisionCandidateMutationKey = () =>
  [`/v1/revision-candidates`] as const

export type CreateRevisionCandidateMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRevisionCandidate>>
>
export type CreateRevisionCandidateMutationError = ErrorType<void>

export const useCreateRevisionCandidate = <TError = ErrorType<void>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createRevisionCandidate>>,
    TError,
    Key,
    CreateRevisionCandidateDto,
    Awaited<ReturnType<typeof createRevisionCandidate>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateRevisionCandidateMutationKey()
  const swrFn = getCreateRevisionCandidateMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listRevisionCandidate = (
  params?: ListRevisionCandidateParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCandidateDto[]>(
    { url: `/v1/revision-candidates`, method: 'GET', params },
    options,
  )
}

export const getListRevisionCandidateKey = (
  params?: ListRevisionCandidateParams,
) => [`/v1/revision-candidates`, ...(params ? [params] : [])] as const

export type ListRevisionCandidateQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRevisionCandidate>>
>
export type ListRevisionCandidateQueryError = ErrorType<unknown>

export const useListRevisionCandidate = <TError = ErrorType<unknown>>(
  params?: ListRevisionCandidateParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listRevisionCandidate>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListRevisionCandidateKey(params) : null))
  const swrFn = () => listRevisionCandidate(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getRevisionCandidate = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCandidateDto>(
    { url: `/v1/revision-candidates/${id}`, method: 'GET' },
    options,
  )
}

export const getGetRevisionCandidateKey = (id: string) =>
  [`/v1/revision-candidates/${id}`] as const

export type GetRevisionCandidateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRevisionCandidate>>
>
export type GetRevisionCandidateQueryError = ErrorType<void>

export const useGetRevisionCandidate = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getRevisionCandidate>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetRevisionCandidateKey(id) : null))
  const swrFn = () => getRevisionCandidate(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateRevisionCandidate = (
  id: string,
  updateRevisionCandidateDto: UpdateRevisionCandidateDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCandidateDto>(
    {
      url: `/v1/revision-candidates/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateRevisionCandidateDto,
    },
    options,
  )
}

export const getUpdateRevisionCandidateMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateRevisionCandidateDto },
  ): Promise<RevisionCandidateDto> => {
    return updateRevisionCandidate(id, arg, options)
  }
}
export const getUpdateRevisionCandidateMutationKey = (id: string) =>
  [`/v1/revision-candidates/${id}`] as const

export type UpdateRevisionCandidateMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRevisionCandidate>>
>
export type UpdateRevisionCandidateMutationError = ErrorType<void>

export const useUpdateRevisionCandidate = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateRevisionCandidate>>,
      TError,
      Key,
      UpdateRevisionCandidateDto,
      Awaited<ReturnType<typeof updateRevisionCandidate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateRevisionCandidateMutationKey(id)
  const swrFn = getUpdateRevisionCandidateMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteRevisionCandidate = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/revision-candidates/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteRevisionCandidateMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteRevisionCandidate(id, options)
  }
}
export const getDeleteRevisionCandidateMutationKey = (id: string) =>
  [`/v1/revision-candidates/${id}`] as const

export type DeleteRevisionCandidateMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRevisionCandidate>>
>
export type DeleteRevisionCandidateMutationError = ErrorType<void>

export const useDeleteRevisionCandidate = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteRevisionCandidate>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteRevisionCandidate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteRevisionCandidateMutationKey(id)
  const swrFn = getDeleteRevisionCandidateMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createRevisionScoringStrategy = (
  createRevisionScoringStrategyDto: CreateRevisionScoringStrategyDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionScoringStrategyDto>(
    {
      url: `/v1/revision-scoring-strategy`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createRevisionScoringStrategyDto,
    },
    options,
  )
}

export const getCreateRevisionScoringStrategyMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateRevisionScoringStrategyDto },
  ): Promise<RevisionScoringStrategyDto> => {
    return createRevisionScoringStrategy(arg, options)
  }
}
export const getCreateRevisionScoringStrategyMutationKey = () =>
  [`/v1/revision-scoring-strategy`] as const

export type CreateRevisionScoringStrategyMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRevisionScoringStrategy>>
>
export type CreateRevisionScoringStrategyMutationError = ErrorType<void>

export const useCreateRevisionScoringStrategy = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createRevisionScoringStrategy>>,
    TError,
    Key,
    CreateRevisionScoringStrategyDto,
    Awaited<ReturnType<typeof createRevisionScoringStrategy>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getCreateRevisionScoringStrategyMutationKey()
  const swrFn = getCreateRevisionScoringStrategyMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listRevisionScoringStrategy = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionScoringStrategyDto[]>(
    { url: `/v1/revision-scoring-strategy`, method: 'GET' },
    options,
  )
}

export const getListRevisionScoringStrategyKey = () =>
  [`/v1/revision-scoring-strategy`] as const

export type ListRevisionScoringStrategyQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRevisionScoringStrategy>>
>
export type ListRevisionScoringStrategyQueryError = ErrorType<unknown>

export const useListRevisionScoringStrategy = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listRevisionScoringStrategy>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListRevisionScoringStrategyKey() : null))
  const swrFn = () => listRevisionScoringStrategy(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getRevisionScoringStrategy = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionScoringStrategyDto>(
    { url: `/v1/revision-scoring-strategy/${id}`, method: 'GET' },
    options,
  )
}

export const getGetRevisionScoringStrategyKey = (id: string) =>
  [`/v1/revision-scoring-strategy/${id}`] as const

export type GetRevisionScoringStrategyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRevisionScoringStrategy>>
>
export type GetRevisionScoringStrategyQueryError = ErrorType<void>

export const useGetRevisionScoringStrategy = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getRevisionScoringStrategy>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetRevisionScoringStrategyKey(id) : null))
  const swrFn = () => getRevisionScoringStrategy(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateRevisionScoringStrategy = (
  id: string,
  updateRevisionScoringStrategyDto: UpdateRevisionScoringStrategyDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionScoringStrategyDto>(
    {
      url: `/v1/revision-scoring-strategy/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateRevisionScoringStrategyDto,
    },
    options,
  )
}

export const getUpdateRevisionScoringStrategyMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateRevisionScoringStrategyDto },
  ): Promise<RevisionScoringStrategyDto> => {
    return updateRevisionScoringStrategy(id, arg, options)
  }
}
export const getUpdateRevisionScoringStrategyMutationKey = (id: string) =>
  [`/v1/revision-scoring-strategy/${id}`] as const

export type UpdateRevisionScoringStrategyMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRevisionScoringStrategy>>
>
export type UpdateRevisionScoringStrategyMutationError = ErrorType<void>

export const useUpdateRevisionScoringStrategy = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateRevisionScoringStrategy>>,
      TError,
      Key,
      UpdateRevisionScoringStrategyDto,
      Awaited<ReturnType<typeof updateRevisionScoringStrategy>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdateRevisionScoringStrategyMutationKey(id)
  const swrFn = getUpdateRevisionScoringStrategyMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteRevisionScoringStrategy = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/revision-scoring-strategy/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteRevisionScoringStrategyMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteRevisionScoringStrategy(id, options)
  }
}
export const getDeleteRevisionScoringStrategyMutationKey = (id: string) =>
  [`/v1/revision-scoring-strategy/${id}`] as const

export type DeleteRevisionScoringStrategyMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRevisionScoringStrategy>>
>
export type DeleteRevisionScoringStrategyMutationError = ErrorType<void>

export const useDeleteRevisionScoringStrategy = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteRevisionScoringStrategy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteRevisionScoringStrategy>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteRevisionScoringStrategyMutationKey(id)
  const swrFn = getDeleteRevisionScoringStrategyMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createRevision = (
  createRevisionDto: CreateRevisionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionDto>(
    {
      url: `/v1/revisions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createRevisionDto,
    },
    options,
  )
}

export const getCreateRevisionMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateRevisionDto },
  ): Promise<RevisionDto> => {
    return createRevision(arg, options)
  }
}
export const getCreateRevisionMutationKey = () => [`/v1/revisions`] as const

export type CreateRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRevision>>
>
export type CreateRevisionMutationError = ErrorType<void>

export const useCreateRevision = <TError = ErrorType<void>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createRevision>>,
    TError,
    Key,
    CreateRevisionDto,
    Awaited<ReturnType<typeof createRevision>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateRevisionMutationKey()
  const swrFn = getCreateRevisionMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listRevision = (
  params?: ListRevisionParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionListDto>(
    { url: `/v1/revisions`, method: 'GET', params },
    options,
  )
}

export const getListRevisionKey = (params?: ListRevisionParams) =>
  [`/v1/revisions`, ...(params ? [params] : [])] as const

export type ListRevisionQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRevision>>
>
export type ListRevisionQueryError = ErrorType<unknown>

export const useListRevision = <TError = ErrorType<unknown>>(
  params?: ListRevisionParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listRevision>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListRevisionKey(params) : null))
  const swrFn = () => listRevision(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getRevision = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionDto>(
    { url: `/v1/revisions/${id}`, method: 'GET' },
    options,
  )
}

export const getGetRevisionKey = (id: string) =>
  [`/v1/revisions/${id}`] as const

export type GetRevisionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRevision>>
>
export type GetRevisionQueryError = ErrorType<void>

export const useGetRevision = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getRevision>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetRevisionKey(id) : null))
  const swrFn = () => getRevision(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateRevision = (
  id: string,
  updateRevisionDto: UpdateRevisionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionDto>(
    {
      url: `/v1/revisions/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateRevisionDto,
    },
    options,
  )
}

export const getUpdateRevisionMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateRevisionDto },
  ): Promise<RevisionDto> => {
    return updateRevision(id, arg, options)
  }
}
export const getUpdateRevisionMutationKey = (id: string) =>
  [`/v1/revisions/${id}`] as const

export type UpdateRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRevision>>
>
export type UpdateRevisionMutationError = ErrorType<void>

export const useUpdateRevision = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateRevision>>,
      TError,
      Key,
      UpdateRevisionDto,
      Awaited<ReturnType<typeof updateRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateRevisionMutationKey(id)
  const swrFn = getUpdateRevisionMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteRevision = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/revisions/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteRevisionMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteRevision(id, options)
  }
}
export const getDeleteRevisionMutationKey = (id: string) =>
  [`/v1/revisions/${id}`] as const

export type DeleteRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRevision>>
>
export type DeleteRevisionMutationError = ErrorType<void>

export const useDeleteRevision = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteRevision>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteRevisionMutationKey(id)
  const swrFn = getDeleteRevisionMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
