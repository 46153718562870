/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum PrimaryVendorConfiguration {
  LATEST_COST = 'LATEST_COST',
  SELECTED_BY_USER = 'SELECTED_BY_USER',
}
