/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum PhysicalGiftCardDisassociationMissingOwnerErrorErrorCode {
  physical_gift_card_disassociation_missing_owner = 'physical_gift_card_disassociation_missing_owner',
}
