/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  CreateStoreVendorDto,
  StoreVendorDto,
  UpdateStoreVendorDto,
  VendorPageDto,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const listStoreVendors = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<VendorPageDto>(
    { url: `/v1/store-vendors`, method: 'GET' },
    options,
  )
}

export const getListStoreVendorsKey = () => [`/v1/store-vendors`] as const

export type ListStoreVendorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listStoreVendors>>
>
export type ListStoreVendorsQueryError = ErrorType<unknown>

export const useListStoreVendors = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listStoreVendors>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListStoreVendorsKey() : null))
  const swrFn = () => listStoreVendors(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createStoreVendor = (
  createStoreVendorDto: CreateStoreVendorDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreVendorDto>(
    {
      url: `/v1/store-vendors`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createStoreVendorDto,
    },
    options,
  )
}

export const getCreateStoreVendorMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateStoreVendorDto },
  ): Promise<StoreVendorDto> => {
    return createStoreVendor(arg, options)
  }
}
export const getCreateStoreVendorMutationKey = () =>
  [`/v1/store-vendors`] as const

export type CreateStoreVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStoreVendor>>
>
export type CreateStoreVendorMutationError = ErrorType<void>

export const useCreateStoreVendor = <TError = ErrorType<void>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createStoreVendor>>,
    TError,
    Key,
    CreateStoreVendorDto,
    Awaited<ReturnType<typeof createStoreVendor>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateStoreVendorMutationKey()
  const swrFn = getCreateStoreVendorMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getStoreVendor = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreVendorDto>(
    { url: `/v1/store-vendors/${id}`, method: 'GET' },
    options,
  )
}

export const getGetStoreVendorKey = (id: string) =>
  [`/v1/store-vendors/${id}`] as const

export type GetStoreVendorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreVendor>>
>
export type GetStoreVendorQueryError = ErrorType<void>

export const useGetStoreVendor = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getStoreVendor>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetStoreVendorKey(id) : null))
  const swrFn = () => getStoreVendor(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateStoreVendor = (
  id: string,
  updateStoreVendorDto: UpdateStoreVendorDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<StoreVendorDto>(
    {
      url: `/v1/store-vendors/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateStoreVendorDto,
    },
    options,
  )
}

export const getUpdateStoreVendorMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateStoreVendorDto },
  ): Promise<StoreVendorDto> => {
    return updateStoreVendor(id, arg, options)
  }
}
export const getUpdateStoreVendorMutationKey = (id: string) =>
  [`/v1/store-vendors/${id}`] as const

export type UpdateStoreVendorMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStoreVendor>>
>
export type UpdateStoreVendorMutationError = ErrorType<void>

export const useUpdateStoreVendor = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateStoreVendor>>,
      TError,
      Key,
      UpdateStoreVendorDto,
      Awaited<ReturnType<typeof updateStoreVendor>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateStoreVendorMutationKey(id)
  const swrFn = getUpdateStoreVendorMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
