/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum RevisionTypeEnum {
  PRODUCT_LIST = 'PRODUCT_LIST',
  VENDOR_COSTS = 'VENDOR_COSTS',
  STORE_VENDOR_CATALOG = 'STORE_VENDOR_CATALOG',
  VENDOR_CATALOG = 'VENDOR_CATALOG',
  VENDOR_INVOICE = 'VENDOR_INVOICE',
  STORE_DEPARTMENTS = 'STORE_DEPARTMENTS',
  STORE_VENDORS = 'STORE_VENDORS',
  VENDOR_INVOICE_HEADER = 'VENDOR_INVOICE_HEADER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  STORE_VENDOR_CATALOG_SYNC = 'STORE_VENDOR_CATALOG_SYNC',
  VENDOR_CATALOG_BARCODE_MIGRATION = 'VENDOR_CATALOG_BARCODE_MIGRATION',
}
