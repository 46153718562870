/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum SendWebhookType {
  messageincoming = 'message.incoming',
  messagestatus = 'message.status',
  recipientoptout = 'recipient.optout',
}
