/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum BarcodeType {
  'EAN-8' = 'EAN-8',
  'EAN-8_Without_Check_Digit' = 'EAN-8 Without Check Digit',
  'EAN-13' = 'EAN-13',
  'EAN-13_Without_Check_Digit' = 'EAN-13 Without Check Digit',
  'EAN-14' = 'EAN-14',
  'EAN-14_Without_Check_Digit' = 'EAN-14 Without Check Digit',
  PLU = 'PLU',
  PRODUCE_PLU = 'PRODUCE PLU',
  'UPC-A' = 'UPC-A',
  'UPC-A_Without_Check_Digit' = 'UPC-A Without Check Digit',
  'UPC-E' = 'UPC-E',
  'UPC-E_Without_Check_Digit' = 'UPC-E Without Check Digit',
  UPC_Shipping_Container = 'UPC Shipping Container',
  Type_2_UPC = 'Type 2 UPC',
  'UPC-A_Type_2_Without_Check_Digit' = 'UPC-A Type 2 Without Check Digit',
  Type_2_EAN = 'Type 2 EAN',
  EAN_13_Type_2_Without_Check_Digit = 'EAN 13 Type 2 Without Check Digit',
}
