/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum InvalidEmployeeErrorErrorCode {
  invalid_employee = 'invalid_employee',
}
