/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum VendorProductStatus {
  UNAVAILABLE = 'UNAVAILABLE',
  DISCONTINUED = 'DISCONTINUED',
  AVAILABLE = 'AVAILABLE',
  STORE_DISCONTINUED = 'STORE_DISCONTINUED',
  MANUFACTURER_DISCONTINUED = 'MANUFACTURER_DISCONTINUED',
}
