/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum OfferType {
  AMOUNT_OFF = 'AMOUNT_OFF',
  BASKET_AMOUNT_OFF = 'BASKET_AMOUNT_OFF',
  PERCENTAGE_OFF = 'PERCENTAGE_OFF',
  PERCENTAGE_OFF_FOR_QUANTITY = 'PERCENTAGE_OFF_FOR_QUANTITY',
  FIXED_PRICE = 'FIXED_PRICE',
  FIXED_PRICE_FOR_QUANTITY = 'FIXED_PRICE_FOR_QUANTITY',
  BOGO = 'BOGO',
}
