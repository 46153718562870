import dayjs from 'dayjs'

import {
  getLocalStorageRecord,
  setLocalStorageRecord,
} from '@vori/dashboard-utils/localStorage'

import { USER_DATA_CACHE_KEY } from './constants'
import { CurrentUserData, CurrentUserMetadata, UserDataFromGQL } from './types'

export const cacheUserData = (userData: CurrentUserData) => {
  setLocalStorageRecord<CurrentUserData>(USER_DATA_CACHE_KEY, userData)
}

export const getCachedUserData = () =>
  getLocalStorageRecord<CurrentUserData>(USER_DATA_CACHE_KEY)

export const removeCachedUserData = () => {
  localStorage.removeItem(USER_DATA_CACHE_KEY)
}

export const getDefaultUserMetadata = (
  userData: CurrentUserData,
): CurrentUserMetadata => {
  /**
   * @todo Remove this once we have a better way to determine the default store
   * or have a store selector in the UI.
   */
  if (userData.companyID === 'UwVEiy0LFS4cWAoRihCL') {
    return {
      selectedStoreID: '352',
      selectedStoreName: 'Berkeley Bowl West',
      timeZone: 'America/Los_Angeles',
    }
  }

  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const defaultStore = userData.retailUser?.retailCompany.store
    ? userData.retailUser?.retailCompany.store
    : userData.retailUser?.retailCompany.banner?.stores?.nodes[0]

  const metadata = defaultStore
    ? {
        selectedStoreID: defaultStore.serialID || null,
        selectedStoreName: defaultStore.name || defaultStore.serialID || null,
        timeZone: defaultStore.local_iana_timezone || defaultTimezone,
      }
    : {
        selectedStoreID: null,
        selectedStoreName: null,
        timeZone: defaultTimezone,
      }

  dayjs.tz.setDefault(metadata.timeZone)

  return metadata
}

export function userDataIsFromGQL(
  userData: UserDataFromGQL | CurrentUserData,
): userData is UserDataFromGQL {
  return Boolean(userData.__typename)
}
