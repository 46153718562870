/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum StoreProductLoaders {
  esls = 'esls',
  active_vendor = 'active_vendor',
  active_vendor_product = 'active_vendor_product',
}
