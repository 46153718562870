/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum TelnyxDeliveryStatus {
  queued = 'queued',
  sending = 'sending',
  sent = 'sent',
  delivered = 'delivered',
  sending_failed = 'sending_failed',
  delivery_failed = 'delivery_failed',
  delivery_unconfirmed = 'delivery_unconfirmed',
  webhook_delivered = 'webhook_delivered',
}
