/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum SlopePaymentStatus {
  unpaid = 'unpaid',
  partiallyPaid = 'partiallyPaid',
  paid = 'paid',
  completed = 'completed',
  refunded = 'refunded',
  opening = 'opening',
  open = 'open',
  canceled = 'canceled',
}
