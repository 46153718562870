/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum InvalidPromotionStateTransitionErrorErrorCode {
  invalid_promotion_state_transition = 'invalid_promotion_state_transition',
}
