/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum PromotionalPriceHigherThanRetailErrorDtoType {
  promotional_price_higher_than_retail = 'promotional_price_higher_than_retail',
}
