import { useToast } from '@vori/gourmet-components'
import { clsx, type ClassValue } from 'clsx'
import { Middleware, SWRResponse } from 'swr'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * A middleware that hides errors on subsequent fetches if there's cached data.
 *
 * - If the initial fetch fails (no cached data yet), an error is shown.
 * - If a subsequent refresh fails but there is already cached data,
 *   we clear the error so you can keep showing the old data without error state.
 */
export const keepDataOnRefetchErrorMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config): SWRResponse => {
    // Use the next middleware/hook in the chain
    const { data, error, mutate, isLoading } = useSWRNext(key, fetcher, config)

    const refreshErrorToast = useToast({
      message: 'Failed to refresh data. Retrying...',
      variant: 'negative',
    })

    // If we have an error and are using `refreshInterval`, show a toast
    if (error && config.refreshInterval) {
      refreshErrorToast.show()
    }

    let safeError = error
    // If we already have data in the cache, ignore new errors
    // by resetting `error` to undefined. Otherwise (initial load),
    // pass the error as-is so that the UI can handle it.
    if (data !== undefined && config.refreshInterval) {
      // We have previously fetched data, so hide new errors
      safeError = undefined
    }

    return {
      data,
      mutate,
      isLoading,
      isValidating: false, // manually set to false to avoid re-renders
      error: safeError,
    }
  }
}
