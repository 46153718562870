import { QueryKey } from '@tanstack/react-query'
import camelCase from 'lodash/camelCase'
import { StoreApi } from 'zustand'
import { ApplicationDataSlice } from './ApplicationDataStore'
import { ErrorManagementSlice } from './ErrorManagementStore'
import { GlobalModalSlice } from './GlobalModalStore'
import { InvoiceDetailsSlice } from './InvoiceDetailsStore'
import { MergeProductsSlice } from './MergeProductsStore'
import { ProductsSlice } from './ProductStore'
import { RevisionSlice } from './RevisionStore'
import { UserSlice } from './UserStore'
import { VendorsSlice } from './VendorsStore'

export enum SearchHistoryReturnOrder {
  'LIFO' = 'last-in-first-out',
  'FIFO' = 'first-in-first-out',
}

export type StateActionStatus = {
  error: unknown | null
  loading: boolean
  success: boolean
}

export type GlobalStoreType = {
  appData: ApplicationDataSlice
  globalModal: GlobalModalSlice
  products: ProductsSlice
  user: UserSlice
  errorManagement: ErrorManagementSlice
  revisions: RevisionSlice
  invoice: InvoiceDetailsSlice
  vendors: VendorsSlice
  mergeProducts: MergeProductsSlice
}

export type GlobalStoreKeys = keyof GlobalStoreType

/**
 * This utility function helps with the concatenation of the store slices.
 * As all of the slices will follow the namespace implementation ( user, produts etc )
 * this will ensure that all slices are focused on their specific functionality. It is the definiteion of a helper function
 * @param sliceName: An automatically typed string version of the appropriate name space ( ref: GlobalStoreKeys )
 * @param setfn: The provided zustang set function
 * @param getfn: The provided zustang get function
 * @param store: A reference to the global namespaced store
 * @returns
 */
export const sliceArgumentsCreator = <
  GlobalStoreKeyGeneric extends GlobalStoreType[keyof GlobalStoreType],
>(
  sliceName: GlobalStoreKeys,
  setfn: (updater: (state: GlobalStoreType) => GlobalStoreType) => void,
  getfn: () => GlobalStoreType,
  store: StoreApi<GlobalStoreType>,
): [
  (partial: Partial<GlobalStoreKeyGeneric>) => void,
  () => GlobalStoreKeyGeneric,
  StoreApi<GlobalStoreKeyGeneric>,
] => {
  // return all 3 items needed by the create_____Slice function [set, get, store]
  return [
    (partial: Partial<GlobalStoreKeyGeneric>) => {
      setfn((state) => ({
        ...state,
        [sliceName]: {
          ...state[sliceName],
          ...partial,
        },
      }))
    },
    () => getfn()[sliceName] as GlobalStoreKeyGeneric,
    store as unknown as StoreApi<GlobalStoreKeyGeneric>, // this is here to ensure that "store" is properly cased as the global store type
  ]
}

export type QueryClientReducerInfo<CachedDataContentType> = {
  has_next_page: boolean
  next_page_number: number
  cachedData: CachedDataContentType[]
}

export const getQueryClientCacheReducer = <PageResultType, ResultContentType>(
  output: QueryClientReducerInfo<ResultContentType>,
  current: [QueryKey, PageResultType | undefined],
) => {
  const [_, results] = current
  if (results) {
    const {
      meta: { has_next_page, page },
      data,
    } = results as unknown as {
      meta: { has_next_page: boolean; page: number }
      data: ResultContentType[]
    }

    output.next_page_number = page + 1
    output.has_next_page = has_next_page
    output.cachedData.push(...data)
  }
  return output
}

export const createSaveDataFromPendingItem = <InputType, OutputType>(
  inputObj: Partial<InputType>,
) => {
  const propList = [
    'barcode',
    'case_cost',
    'case_size',
    'case_size_decimal',
    'case_upc',
    'description',
    'each_cost',
    'item_code',
    'lb_cost',
    'pack_upc',
    'store_vendor_id',
    'unit_upc',
  ]

  const generatedObejct = propList.reduce((output, current) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (inputObj[current]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[camelCase(current)] = inputObj[current]
    }
    return output
  }, {} as OutputType)
  return generatedObejct
}
