export const INVOICE_DETAIL_ENDPOINT =
  '/api/ap-workflow/v1/invoice/details' as const

export const INVOICES_ACTION_ENDPOINT = '/api/ap-workflow/v1/invoices' as const

export const PRODUCT_MAPPING_ENDPOINT =
  '/api/ap-workflow/v1/invoice/line-item/edit'

export const LINE_ITEM_DESCRIPTION_COLUMN_WIDTH = 340 as const
export const STORE_DEFAULT_MARGIN = 0.35

export const sentryErrorTagPrefix = 'invoice'

export const FUZZY_SEARCH_THRESHOLD = 0.2 as const
