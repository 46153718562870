import Axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { getHTTPHeaders } from '@vori/dashboard-api/getHTTPHeaders'
import { NetworkErrorSources, SentryTags } from '@vori/dashboard-constants'
import { ENV_GAE_REST_ENDPOINT } from '@vori/dashboard-env'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
export const AXIOS_INSTANCE = Axios.create({})

// Set the base URL and common headers
AXIOS_INSTANCE.interceptors.request.use(async (config) => {
  const headers = (await getHTTPHeaders()) || undefined
  config.headers.set(headers, true)
  return {
    ...config,
    baseURL: ENV_GAE_REST_ENDPOINT,
  }
})

// Convert date strings to Dates
AXIOS_INSTANCE.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data)
  return originalResponse
})

const isoDateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

export function isIsoDateString(value: unknown): boolean {
  return !!value && typeof value === 'string' && isoDateFormat.test(value)
}

/**
 * Convert the date fields to Date objects.
 *
 * Adapted from https://orval.dev/reference/configuration/output#usedates.
 */
export function handleDates(body: object | unknown) {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body
  }

  for (const key of Object.keys(body)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = body[key]
    if (isIsoDateString(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      body[key] = new Date(value) // default JS conversion
    } else if (typeof value === 'object') {
      handleDates(value)
    }
  }
}

export const customAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source()

  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  })
    .then((response) => {
      if (!response) {
        throw new Error('response is empty or undefined')
      }

      return response.data
    })
    .catch((err) => {
      captureException(
        (scope) => {
          // stringify params' values so that they'll properly show up in Sentry
          const params = err.config.params
            ? JSON.parse(JSON.stringify(err.config.params))
            : undefined

          scope.setContext('Request', {
            baseURL: err.config.baseURL,
            url: err.config.url,
            method: err.config.method,
            params,
            data: err.config.data,
            apiClient: NetworkErrorSources.ORVAL,
          })
          return err as Error
        },
        {
          sentryTag: SentryTags.NETWORK_EXCEPTION,
        },
      )
      throw err
    })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>
