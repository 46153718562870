/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Arguments, Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  APLRevisionDTO,
  APLRevisionGetTransactionSetsResponse,
  APLRevisionInMemorySummaryInputDTO,
  APLRevisionLoadFromFileDTO,
  APLRevisionSchemaResponseDTO,
  APLRevisionSetTransactionsDTO,
  APLRevisionUpdateTransactionDTO,
  APLRevisionsControllerFindAllParams,
  CreateRevisionDto,
  CreateRevisionRowsDTO,
  InvoiceVendorLineMappingDTO,
  PreviewFileParams,
  RevisionCommitRequestDTO,
  RevisionCommitResponseDTO,
  RevisionCopyDTO,
  RevisionFileImportResponseDTO,
  RevisionFileImportRowsDTO,
  RevisionFilePreviewResponseDTO,
  RevisionFileResponseDTO,
  RevisionFileUploadDTO,
  RevisionProgressSummaryDTO,
  RevisionResetResponseDTO,
  RevisionSchemaDTO,
  SuccessResponseDTO,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'
import { customFormData } from '../../custom-form-data'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const aPLRevisionsControllerFindAll = (
  params?: APLRevisionsControllerFindAllParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/apl-revisions/revisions`, method: 'GET', params },
    options,
  )
}

export const getAPLRevisionsControllerFindAllKey = (
  params?: APLRevisionsControllerFindAllParams,
) => [`/apl-revisions/revisions`, ...(params ? [params] : [])] as const

export type APLRevisionsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof aPLRevisionsControllerFindAll>>
>
export type APLRevisionsControllerFindAllQueryError = ErrorType<unknown>

export const useAPLRevisionsControllerFindAll = <TError = ErrorType<unknown>>(
  params?: APLRevisionsControllerFindAllParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof aPLRevisionsControllerFindAll>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getAPLRevisionsControllerFindAllKey(params) : null))
  const swrFn = () => aPLRevisionsControllerFindAll(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createRevisionLegacy = (
  createRevisionDto: CreateRevisionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionDTO>(
    {
      url: `/apl-revisions/revisions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createRevisionDto,
    },
    options,
  )
}

export const getCreateRevisionLegacyMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateRevisionDto },
  ): Promise<APLRevisionDTO> => {
    return createRevisionLegacy(arg, options)
  }
}
export const getCreateRevisionLegacyMutationKey = () =>
  [`/apl-revisions/revisions`] as const

export type CreateRevisionLegacyMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRevisionLegacy>>
>
export type CreateRevisionLegacyMutationError = ErrorType<unknown>

export const useCreateRevisionLegacy = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createRevisionLegacy>>,
    TError,
    Key,
    CreateRevisionDto,
    Awaited<ReturnType<typeof createRevisionLegacy>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateRevisionLegacyMutationKey()
  const swrFn = getCreateRevisionLegacyMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const copyRevision = (
  revisionCopyDTO: RevisionCopyDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionDTO>(
    {
      url: `/apl-revisions/revisions/copy`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: revisionCopyDTO,
    },
    options,
  )
}

export const getCopyRevisionMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: RevisionCopyDTO },
  ): Promise<APLRevisionDTO> => {
    return copyRevision(arg, options)
  }
}
export const getCopyRevisionMutationKey = () =>
  [`/apl-revisions/revisions/copy`] as const

export type CopyRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof copyRevision>>
>
export type CopyRevisionMutationError = ErrorType<unknown>

export const useCopyRevision = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof copyRevision>>,
    TError,
    Key,
    RevisionCopyDTO,
    Awaited<ReturnType<typeof copyRevision>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCopyRevisionMutationKey()
  const swrFn = getCopyRevisionMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getRevisionLegacy = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionDTO>(
    { url: `/apl-revisions/revisions/${id}`, method: 'GET' },
    options,
  )
}

export const getGetRevisionLegacyKey = (id: string) =>
  [`/apl-revisions/revisions/${id}`] as const

export type GetRevisionLegacyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRevisionLegacy>>
>
export type GetRevisionLegacyQueryError = ErrorType<unknown>

export const useGetRevisionLegacy = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getRevisionLegacy>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetRevisionLegacyKey(id) : null))
  const swrFn = () => getRevisionLegacy(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const commitRevision = (
  revisionID: string,
  revisionCommitRequestDTO: RevisionCommitRequestDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCommitResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/commit`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: revisionCommitRequestDTO,
    },
    options,
  )
}

export const getCommitRevisionMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: RevisionCommitRequestDTO },
  ): Promise<RevisionCommitResponseDTO> => {
    return commitRevision(revisionID, arg, options)
  }
}
export const getCommitRevisionMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/commit`] as const

export type CommitRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof commitRevision>>
>
export type CommitRevisionMutationError = ErrorType<unknown>

export const useCommitRevision = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof commitRevision>>,
      TError,
      Key,
      RevisionCommitRequestDTO,
      Awaited<ReturnType<typeof commitRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCommitRevisionMutationKey(revisionID)
  const swrFn = getCommitRevisionMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getRevisionFiles = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionFileResponseDTO>(
    { url: `/apl-revisions/revisions/${revisionID}/files`, method: 'GET' },
    options,
  )
}

export const getGetRevisionFilesKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/files`] as const

export type GetRevisionFilesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRevisionFiles>>
>
export type GetRevisionFilesQueryError = ErrorType<unknown>

export const useGetRevisionFiles = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getRevisionFiles>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetRevisionFilesKey(revisionID) : null))
  const swrFn = () => getRevisionFiles(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const importRevisionFile = (
  revisionID: string,
  revisionFileImportRowsDTO: RevisionFileImportRowsDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionFileImportResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/files/import`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: revisionFileImportRowsDTO,
    },
    options,
  )
}

export const getImportRevisionFileMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: RevisionFileImportRowsDTO },
  ): Promise<RevisionFileImportResponseDTO> => {
    return importRevisionFile(revisionID, arg, options)
  }
}
export const getImportRevisionFileMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/files/import`] as const

export type ImportRevisionFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof importRevisionFile>>
>
export type ImportRevisionFileMutationError = ErrorType<unknown>

export const useImportRevisionFile = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof importRevisionFile>>,
      TError,
      Key,
      RevisionFileImportRowsDTO,
      Awaited<ReturnType<typeof importRevisionFile>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getImportRevisionFileMutationKey(revisionID)
  const swrFn = getImportRevisionFileMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const previewFile = (
  revisionID: string,
  params: PreviewFileParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionFilePreviewResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/files/preview`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getPreviewFileKey = (
  revisionID: string,
  params: PreviewFileParams,
) =>
  [
    `/apl-revisions/revisions/${revisionID}/files/preview`,
    ...(params ? [params] : []),
  ] as const

export type PreviewFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof previewFile>>
>
export type PreviewFileQueryError = ErrorType<unknown>

export const usePreviewFile = <TError = ErrorType<unknown>>(
  revisionID: string,
  params: PreviewFileParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof previewFile>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getPreviewFileKey(revisionID, params) : null))
  const swrFn = () => previewFile(revisionID, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const uploadRevisionFiles = (
  revisionID: string,
  revisionFileUploadDTO: RevisionFileUploadDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  const formData = customFormData(revisionFileUploadDTO)
  return customAxiosInstance<RevisionFileResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/files/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

export const getUploadRevisionFilesMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: RevisionFileUploadDTO },
  ): Promise<RevisionFileResponseDTO> => {
    return uploadRevisionFiles(revisionID, arg, options)
  }
}
export const getUploadRevisionFilesMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/files/upload`] as const

export type UploadRevisionFilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadRevisionFiles>>
>
export type UploadRevisionFilesMutationError = ErrorType<unknown>

export const useUploadRevisionFiles = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof uploadRevisionFiles>>,
      TError,
      Key,
      RevisionFileUploadDTO,
      Awaited<ReturnType<typeof uploadRevisionFiles>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUploadRevisionFilesMutationKey(revisionID)
  const swrFn = getUploadRevisionFilesMutationFetcher(
    revisionID,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const aPLRevisionsControllerGetInitialFrameSchema = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionSchemaResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/initial/schema`,
      method: 'GET',
    },
    options,
  )
}

export const getAPLRevisionsControllerGetInitialFrameSchemaKey = (
  revisionID: string,
) => [`/apl-revisions/revisions/${revisionID}/initial/schema`] as const

export type APLRevisionsControllerGetInitialFrameSchemaQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof aPLRevisionsControllerGetInitialFrameSchema>>
  >
export type APLRevisionsControllerGetInitialFrameSchemaQueryError =
  ErrorType<unknown>

export const useAPLRevisionsControllerGetInitialFrameSchema = <
  TError = ErrorType<unknown>,
>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof aPLRevisionsControllerGetInitialFrameSchema>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getAPLRevisionsControllerGetInitialFrameSchemaKey(revisionID)
        : null)
  const swrFn = () =>
    aPLRevisionsControllerGetInitialFrameSchema(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const load = (
  revisionID: string,
  aPLRevisionLoadFromFileDTO: APLRevisionLoadFromFileDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionResetResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/load`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aPLRevisionLoadFromFileDTO,
    },
    options,
  )
}

export const getLoadMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: APLRevisionLoadFromFileDTO },
  ): Promise<RevisionResetResponseDTO> => {
    return load(revisionID, arg, options)
  }
}
export const getLoadMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/load`] as const

export type LoadMutationResult = NonNullable<Awaited<ReturnType<typeof load>>>
export type LoadMutationError = ErrorType<unknown>

export const useLoad = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof load>>,
      TError,
      Key,
      APLRevisionLoadFromFileDTO,
      Awaited<ReturnType<typeof load>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getLoadMutationKey(revisionID)
  const swrFn = getLoadMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const passThroughRevision = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionCommitResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/passthrough`,
      method: 'POST',
    },
    options,
  )
}

export const getPassThroughRevisionMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    __: { arg: Arguments },
  ): Promise<RevisionCommitResponseDTO> => {
    return passThroughRevision(revisionID, options)
  }
}
export const getPassThroughRevisionMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/passthrough`] as const

export type PassThroughRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof passThroughRevision>>
>
export type PassThroughRevisionMutationError = ErrorType<unknown>

export const usePassThroughRevision = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof passThroughRevision>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof passThroughRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getPassThroughRevisionMutationKey(revisionID)
  const swrFn = getPassThroughRevisionMutationFetcher(
    revisionID,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const aPLRevisionsControllerGetProductFrameSchema = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionSchemaResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/products/schema`,
      method: 'GET',
    },
    options,
  )
}

export const getAPLRevisionsControllerGetProductFrameSchemaKey = (
  revisionID: string,
) => [`/apl-revisions/revisions/${revisionID}/products/schema`] as const

export type APLRevisionsControllerGetProductFrameSchemaQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof aPLRevisionsControllerGetProductFrameSchema>>
  >
export type APLRevisionsControllerGetProductFrameSchemaQueryError =
  ErrorType<unknown>

export const useAPLRevisionsControllerGetProductFrameSchema = <
  TError = ErrorType<unknown>,
>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof aPLRevisionsControllerGetProductFrameSchema>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getAPLRevisionsControllerGetProductFrameSchemaKey(revisionID)
        : null)
  const swrFn = () =>
    aPLRevisionsControllerGetProductFrameSchema(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getRevisionProgress = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionProgressSummaryDTO>(
    { url: `/apl-revisions/revisions/${revisionID}/progress`, method: 'GET' },
    options,
  )
}

export const getGetRevisionProgressKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/progress`] as const

export type GetRevisionProgressQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRevisionProgress>>
>
export type GetRevisionProgressQueryError = ErrorType<unknown>

export const useGetRevisionProgress = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getRevisionProgress>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetRevisionProgressKey(revisionID) : null))
  const swrFn = () => getRevisionProgress(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const resetRevision = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionResetResponseDTO>(
    { url: `/apl-revisions/revisions/${revisionID}/reset`, method: 'POST' },
    options,
  )
}

export const getResetRevisionMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    __: { arg: Arguments },
  ): Promise<RevisionResetResponseDTO> => {
    return resetRevision(revisionID, options)
  }
}
export const getResetRevisionMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/reset`] as const

export type ResetRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetRevision>>
>
export type ResetRevisionMutationError = ErrorType<unknown>

export const useResetRevision = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof resetRevision>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof resetRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getResetRevisionMutationKey(revisionID)
  const swrFn = getResetRevisionMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createRevisionRows = (
  revisionID: string,
  createRevisionRowsDTO: CreateRevisionRowsDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/rows`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createRevisionRowsDTO,
    },
    options,
  )
}

export const getCreateRevisionRowsMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateRevisionRowsDTO },
  ): Promise<APLRevisionDTO> => {
    return createRevisionRows(revisionID, arg, options)
  }
}
export const getCreateRevisionRowsMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/rows`] as const

export type CreateRevisionRowsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createRevisionRows>>
>
export type CreateRevisionRowsMutationError = ErrorType<unknown>

export const useCreateRevisionRows = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof createRevisionRows>>,
      TError,
      Key,
      CreateRevisionRowsDTO,
      Awaited<ReturnType<typeof createRevisionRows>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getCreateRevisionRowsMutationKey(revisionID)
  const swrFn = getCreateRevisionRowsMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const aPLRevisionsControllerGetRevisionSchema = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionSchemaDTO>(
    { url: `/apl-revisions/revisions/${revisionID}/schema`, method: 'GET' },
    options,
  )
}

export const getAPLRevisionsControllerGetRevisionSchemaKey = (
  revisionID: string,
) => [`/apl-revisions/revisions/${revisionID}/schema`] as const

export type APLRevisionsControllerGetRevisionSchemaQueryResult = NonNullable<
  Awaited<ReturnType<typeof aPLRevisionsControllerGetRevisionSchema>>
>
export type APLRevisionsControllerGetRevisionSchemaQueryError =
  ErrorType<unknown>

export const useAPLRevisionsControllerGetRevisionSchema = <
  TError = ErrorType<unknown>,
>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof aPLRevisionsControllerGetRevisionSchema>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getAPLRevisionsControllerGetRevisionSchemaKey(revisionID)
        : null)
  const swrFn = () =>
    aPLRevisionsControllerGetRevisionSchema(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const summarizeRevision = (
  revisionID: string,
  aPLRevisionInMemorySummaryInputDTO: APLRevisionInMemorySummaryInputDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<RevisionResetResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/summary`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aPLRevisionInMemorySummaryInputDTO,
    },
    options,
  )
}

export const getSummarizeRevisionMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: APLRevisionInMemorySummaryInputDTO },
  ): Promise<RevisionResetResponseDTO> => {
    return summarizeRevision(revisionID, arg, options)
  }
}
export const getSummarizeRevisionMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/summary`] as const

export type SummarizeRevisionMutationResult = NonNullable<
  Awaited<ReturnType<typeof summarizeRevision>>
>
export type SummarizeRevisionMutationError = ErrorType<unknown>

export const useSummarizeRevision = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof summarizeRevision>>,
      TError,
      Key,
      APLRevisionInMemorySummaryInputDTO,
      Awaited<ReturnType<typeof summarizeRevision>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getSummarizeRevisionMutationKey(revisionID)
  const swrFn = getSummarizeRevisionMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getTransactionSets = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionGetTransactionSetsResponse>(
    {
      url: `/apl-revisions/revisions/${revisionID}/transaction-sets`,
      method: 'GET',
    },
    options,
  )
}

export const getGetTransactionSetsKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/transaction-sets`] as const

export type GetTransactionSetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionSets>>
>
export type GetTransactionSetsQueryError = ErrorType<unknown>

export const useGetTransactionSets = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTransactionSets>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!revisionID
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTransactionSetsKey(revisionID) : null))
  const swrFn = () => getTransactionSets(revisionID, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateTransaction = (
  revisionID: string,
  aPLRevisionUpdateTransactionDTO: APLRevisionUpdateTransactionDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionSchemaResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/transactions`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: aPLRevisionUpdateTransactionDTO,
    },
    options,
  )
}

export const getUpdateTransactionMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: APLRevisionUpdateTransactionDTO },
  ): Promise<APLRevisionSchemaResponseDTO> => {
    return updateTransaction(revisionID, arg, options)
  }
}
export const getUpdateTransactionMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/transactions`] as const

export type UpdateTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTransaction>>
>
export type UpdateTransactionMutationError = ErrorType<unknown>

export const useUpdateTransaction = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateTransaction>>,
      TError,
      Key,
      APLRevisionUpdateTransactionDTO,
      Awaited<ReturnType<typeof updateTransaction>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdateTransactionMutationKey(revisionID)
  const swrFn = getUpdateTransactionMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const setTransactions = (
  revisionID: string,
  aPLRevisionSetTransactionsDTO: APLRevisionSetTransactionsDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<APLRevisionSchemaResponseDTO>(
    {
      url: `/apl-revisions/revisions/${revisionID}/transactions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: aPLRevisionSetTransactionsDTO,
    },
    options,
  )
}

export const getSetTransactionsMutationFetcher = (
  revisionID: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: APLRevisionSetTransactionsDTO },
  ): Promise<APLRevisionSchemaResponseDTO> => {
    return setTransactions(revisionID, arg, options)
  }
}
export const getSetTransactionsMutationKey = (revisionID: string) =>
  [`/apl-revisions/revisions/${revisionID}/transactions`] as const

export type SetTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof setTransactions>>
>
export type SetTransactionsMutationError = ErrorType<unknown>

export const useSetTransactions = <TError = ErrorType<unknown>>(
  revisionID: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof setTransactions>>,
      TError,
      Key,
      APLRevisionSetTransactionsDTO,
      Awaited<ReturnType<typeof setTransactions>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getSetTransactionsMutationKey(revisionID)
  const swrFn = getSetTransactionsMutationFetcher(revisionID, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createVendorLineMapping = (
  invoiceVendorLineMappingDTO: InvoiceVendorLineMappingDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<SuccessResponseDTO>(
    {
      url: `/apl-revisions/vendor-line-mappings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: invoiceVendorLineMappingDTO,
    },
    options,
  )
}

export const getCreateVendorLineMappingMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: InvoiceVendorLineMappingDTO },
  ): Promise<SuccessResponseDTO> => {
    return createVendorLineMapping(arg, options)
  }
}
export const getCreateVendorLineMappingMutationKey = () =>
  [`/apl-revisions/vendor-line-mappings`] as const

export type CreateVendorLineMappingMutationResult = NonNullable<
  Awaited<ReturnType<typeof createVendorLineMapping>>
>
export type CreateVendorLineMappingMutationError = ErrorType<unknown>

export const useCreateVendorLineMapping = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createVendorLineMapping>>,
    TError,
    Key,
    InvoiceVendorLineMappingDTO,
    Awaited<ReturnType<typeof createVendorLineMapping>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateVendorLineMappingMutationKey()
  const swrFn = getCreateVendorLineMappingMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
